/* ==========================================================================
   #ANIMATIONS
   ========================================================================== */

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes collapsePreheader {
  0% {
    height: 100vh;
  }

  100% {
    height: 0;
  }
}

@keyframes collapseExpandToggle {
  0% {
    width: 100%;
  }

  100% {
    width: 0;
  }
}
