@charset "UTF-8";
/*
  Project: Test Chisel One
  Author: Tomasz Nowak
 */
/* Fonts
   ========================================================================== */
/* Breakpoints
   ========================================================================== */
/* Colors
   ========================================================================== */
.is-hidden {
  display: none;
}

/* stylelint-disable */
/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */
/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

/* ==========================================================================
   #FONT-FACE
   ========================================================================== */
/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */
/* ==========================================================================
   #NORMALIZE
   ========================================================================== */
@import-normalize;
/* ==========================================================================
   #RESET
   ========================================================================== */
/**
 * A very simple reset that sits on top of Normalize.css.
 */
body,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
pre,
dl,
dd,
ol,
ul,
figure,
hr,
fieldset,
legend {
  margin: 0;
  padding: 0;
}

/**
 * Remove trailing margins from nested lists.
 */
li > ol,
li > ul {
  margin-bottom: 0;
}

/**
 * Remove default table spacing.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */
fieldset {
  min-width: 0;
  /* [1] */
  border: 0;
}

/* ==========================================================================
   #SHARED
   ========================================================================== */
/**
 * Shared declarations for certain elements.
 */
/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */
address,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
pre,
dl,
ol,
ul,
figure,
hr,
table,
fieldset {
  margin-bottom: 1.3rem;
}

/**
 * Consistent indentation for lists.
 */
dd,
ol,
ul {
  margin-left: 1rem;
}

/* stylelint-disable */
article {
  max-width: 65rem;
  margin: 0 auto;
}
@media (min-width: 768px) {
  article {
    position: relative;
    z-index: 10;
  }
}
.is_bg_image article * {
  color: #fff;
  border-color: #fff;
  background-color: transparent;
}

/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */
blockquote {
  quotes: "“" "”" "‘" "’";
  font-style: italic;
}
blockquote p:first-child::before {
  content: open-quote;
}
blockquote p:last-child::after {
  content: close-quote;
}

/* ==========================================================================
   #BODY
   ========================================================================== */
/* stylelint-disable */
body {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2rem;
  overflow: hidden;
}
body.is_bg_image {
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}
body.is-safari.is_bg_image::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.c-embed--video {
  position: relative;
  width: 100%;
  padding-top: 56.6%;
}
.is-visible .c-embed--video {
  display: block;
}
.c-embed--video iframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

figcaption {
  font-size: 1rem;
}

/* ==========================================================================
   #HEADINGS
   ========================================================================== */
/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */
h1 {
  font-size: 2.5rem;
  line-height: 1.2;
}
@media not all and (min-resolution: 0.001dpcm) {
  h1 {
    letter-spacing: -2px;
  }
}

h2 {
  font-size: 2.5rem;
}

h3 {
  font-size: 1.8rem;
}

h4 {
  font-size: 1.8rem;
}

h5 {
  font-size: 1.8rem;
}

h6 {
  font-size: 1.8rem;
}

/* stylelint-disable */
body:not(.archive) h2 {
  margin: 4rem 0;
  font-weight: normal;
  text-transform: uppercase;
  text-align: center;
}
@media not all and (min-resolution: 0.001dpcm) {
  body:not(.archive) h2 {
    letter-spacing: -2px;
  }
}

body.is_bg_image:not(.archive) h2 {
  border-color: #fff;
}

/* ==========================================================================
   #HR
   ========================================================================== */
hr {
  display: block;
}

/* ==========================================================================
   #PAGE
   ========================================================================== */
/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */
html {
  background: #fff;
  color: #000;
  font-family: "ABCCamera_Regular", sans-serif;
  font-size: 62.5%;
  /* [1] */
  /* 13px */
  line-height: 1.5;
  /* [1] */
  min-height: 100%;
  /* [3] */
  overflow-y: scroll;
  /* [2] */
  padding: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */
/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */
img {
  font-style: italic;
  /* [2] */
  max-width: 100%;
  /* [1] */
  vertical-align: middle;
  /* [3] */
}

/* ==========================================================================
   #LINKS
   ========================================================================== */
a {
  color: #000;
  text-decoration: underline;
}
a:hover {
  color: #000;
}

/* ==========================================================================
   #LISTS
   ========================================================================== */
li {
  margin-bottom: 0.5rem;
}
li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

dt {
  font-family: "ABCCamera_Bold", sans-serif;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

/* ==========================================================================
   #EM
   ========================================================================== */
strong {
  font-family: "ABCCamera_Bold", sans-serif;
  font-weight: bold;
}

/* ==========================================================================
   #TABLES
   ========================================================================== */
/**
 * 1. Ensure tables fill up as much space as possible.
 */
table {
  width: 100%;
  /* [1] */
}

/* ==========================================================================
   #WP BLOCKS
   ==========================================================================
   Styling for WordPress blocks
   */
.wp-block-post-template {
  margin-left: 0;
}

.c-main .o-main-content .wp-block-file {
  font-size: inherit;
  margin: 0;
  line-height: inherit;
}

/* stylelint-disable */
@media only screen and (max-width: 1099px) {
  #cookie-notice:not(.cookie-notice-hidden) .cookie-notice-container {
    display: flex !important;
    gap: 20px;
  }
}
#cookie-notice .cookie-notice-container {
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  min-height: 8rem;
}
#cookie-notice .cookie-notice-container #cn-notice-buttons.cn-buttons-container {
  display: flex !important;
}
@media only screen and (max-width: 1099px) {
  #cookie-notice .cookie-notice-container #cn-notice-buttons.cn-buttons-container {
    flex-direction: column;
    justify-content: center;
  }
}
@media only screen and (min-width: 1100px) {
  #cookie-notice .cookie-notice-container #cn-notice-buttons.cn-buttons-container {
    width: 100%;
    top: 50%;
    left: 50%;
    max-width: 137rem;
    position: absolute;
    transform: translate(-50%, -50%);
    padding: 0 1rem;
  }
}
@media only screen and (min-width: 1390px) {
  #cookie-notice .cookie-notice-container #cn-notice-buttons.cn-buttons-container {
    padding: 0;
  }
}
#cookie-notice .cookie-notice-container #cn-more-info.cn-button.cn-more-info {
  margin: 0;
  text-transform: lowercase;
}
@media only screen and (max-width: 1099px) {
  #cookie-notice .cookie-notice-container #cn-more-info.cn-button.cn-more-info {
    position: absolute;
    bottom: 2.5rem;
    left: 3rem;
    font-size: 1.2rem;
  }
}
@media only screen and (min-width: 1100px) {
  #cookie-notice .cookie-notice-container #cn-more-info.cn-button.cn-more-info {
    order: -1;
    margin-right: auto;
    margin-left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem !important;
  }
}
#cookie-notice .cookie-notice-container .cn-button {
  text-decoration: none;
  padding: 0 !important;
}
#cookie-notice .cookie-notice-container #cn-accept-cookie {
  color: transparent;
  font-size: 0;
  line-height: 0;
}
@media only screen and (max-width: 1099px) {
  #cookie-notice .cookie-notice-container #cn-accept-cookie::after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='53' height='53' viewBox='0 0 53 53' fill='none'%3E%3Cpath d='M46.2107 26.4998C46.2107 15.2183 37.249 6.07275 26.1942 6.07275C15.1394 6.07275 6.17773 15.2183 6.17773 26.4998C6.17773 37.7814 15.1394 46.9269 26.1942 46.9269C37.249 46.9269 46.2107 37.7814 46.2107 26.4998Z' stroke='%231B1B1B' stroke-width='1.5'/%3E%3Cpath d='M19.7012 26.5002L24.0291 30.9168L32.6848 22.0835' stroke='%231B1B1B' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  }
}
@media only screen and (min-width: 1100px) {
  #cookie-notice .cookie-notice-container #cn-accept-cookie::after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M21.25 12C21.25 6.89137 17.1086 2.75 12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12Z' stroke='%231B1B1B' stroke-width='1.5'/%3E%3Cpath d='M9 12L11 14L15 10' stroke='%231B1B1B' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  }
}
#cookie-notice .cookie-notice-container #cn-refuse-cookie {
  color: transparent;
  font-size: 0;
  line-height: 0;
}
@media only screen and (max-width: 1099px) {
  #cookie-notice .cookie-notice-container #cn-refuse-cookie::after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='54' height='54' viewBox='0 0 54 54' fill='none'%3E%3Cpath d='M31.0925 22.5L22.2734 31.5' stroke='%231B1B1B' stroke-width='1.5' stroke-linecap='round'/%3E%3Cpath d='M31.0925 31.5L22.2734 22.5' stroke='%231B1B1B' stroke-width='1.5' stroke-linecap='round'/%3E%3Cpath d='M47.0774 27C47.0774 15.5056 37.9466 6.1875 26.6832 6.1875C15.4198 6.1875 6.28906 15.5056 6.28906 27C6.28906 38.4944 15.4198 47.8125 26.6832 47.8125C37.9466 47.8125 47.0774 38.4944 47.0774 27Z' stroke='%231B1B1B' stroke-width='1.5'/%3E%3C/svg%3E");
  }
}
@media only screen and (min-width: 1100px) {
  #cookie-notice .cookie-notice-container #cn-refuse-cookie::after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M14 10L10 14' stroke='%231B1B1B' stroke-width='1.5' stroke-linecap='round'/%3E%3Cpath d='M14 14L10 10' stroke='%231B1B1B' stroke-width='1.5' stroke-linecap='round'/%3E%3Cpath d='M21.25 12C21.25 6.89137 17.1086 2.75 12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12Z' stroke='%231B1B1B' stroke-width='1.5'/%3E%3C/svg%3E");
  }
}

#cn-notice-text.cn-text-container {
  display: block;
  margin: 1rem auto;
}

@media only screen and (max-width: 1099px) {
  #cn-notice-text.cn-text-container {
    text-align: left;
    margin: 0 auto 5rem;
  }
  #cn-notice-text.cn-text-container::before {
    content: "ciasteczka";
    display: block;
    font-size: 1.2rem;
    margin: 1rem 0 2rem;
  }
}
@media only screen and (min-width: 1100px) {
  #cn-notice-text.cn-text-container {
    max-width: 86rem;
  }
}
#cn-close-notice {
  display: none;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #000;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
.pawilon-event__meta {
  margin-bottom: 0.5rem;
}
@media (max-width: 767.98px) {
  .pawilon-event__meta {
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 768px) {
  .pawilon-event__meta {
    margin-left: auto;
  }
}

.pawilon-event__info {
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid;
  margin-bottom: 4rem;
}
@media (min-width: 768px) {
  .pawilon-event__info {
    flex-direction: row;
    align-items: flex-end;
  }
}
.pawilon-event__info .pawilon-event__date {
  font-size: 4rem;
  line-height: 4rem;
  flex: 0 0 25%;
  margin-bottom: 0.5rem;
}
@media (max-width: 767.98px) {
  .pawilon-event__info .pawilon-event__date {
    font-size: 10rem;
    line-height: 1;
  }
}
.pawilon-event__info .pawilon-event__title {
  font-size: 1.8rem;
  line-height: 2.2rem;
  margin-bottom: 0.75rem;
  text-transform: none;
  font-weight: bold;
  text-align: left;
}
@media (max-width: 767.98px) {
  .pawilon-event__info .pawilon-event__title {
    margin-top: 8rem;
    font-size: 3.3rem;
    line-height: 1;
  }
}
.pawilon-event__info .pawilon-event__tag {
  text-decoration: none;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: #000;
  display: block;
}

@media (min-width: 768px) {
  .pawilon-event {
    padding-bottom: 26rem;
  }
}
@media (min-width: 1024px) {
  .pawilon-event {
    padding-bottom: 0;
  }
}
.pawilon-event .tribe-events-event-image img {
  width: 100%;
  height: auto;
}

.pawilon-event__content {
  margin-bottom: 5rem;
}
@media (min-width: 1024px) {
  .pawilon-event__content {
    margin-bottom: 0;
  }
}

.pawilon-event__ticket {
  display: flex;
  width: 11.3rem;
  height: 11.3rem;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  border: 2px solid;
  color: #000;
  background: #fff;
  text-decoration: none;
  text-transform: uppercase;
  margin: 0 auto;
}
@media (min-width: 1024px) {
  .pawilon-event__ticket {
    position: absolute;
    top: 0;
    right: 50%;
    transform: translateX(48rem);
    z-index: 1;
    margin: 0 auto;
  }
}

.pawilon-event__mobile-featured {
  display: block;
}
@media (min-width: 768px) {
  .pawilon-event__mobile-featured {
    display: none;
  }
}

.pawilon-event__desktop-featured {
  display: none;
}
@media (min-width: 768px) {
  .pawilon-event__desktop-featured {
    display: block;
  }
}

/* ==========================================================================
   #LAYOUT
   ========================================================================== */
/* Simple flexbox layout system */
.o-layout {
  display: block;
}
@media (min-width: 768px) {
  .o-layout {
    display: flex;
  }
}

.o-layout__item {
  flex: 1;
}

.o-layout--guttered {
  justify-content: space-between;
}
.o-layout--guttered > .o-layout__item {
  flex: none;
}

@media (min-width: 768px) {
  .o-layout--2 > .o-layout__item {
    width: 49%;
  }
}

@media (min-width: 768px) {
  .o-layout--3 > .o-layout__item {
    width: 32%;
  }
}

@media (min-width: 768px) {
  .o-layout--4 > .o-layout__item {
    width: 24%;
  }
}

/* ==========================================================================
   #LIST-BARE
   ========================================================================== */
/**
 * Strip list-like appearance from lists by removing their bullets, and any
 * indentation.
 */
.o-list-bare {
  list-style: none;
  margin-left: 0;
}

/* ==========================================================================
   #LIST-INLINE
   ========================================================================== */
/**
 * The list-inline object simply displays a list of items in one line.
 */
.o-list-inline {
  list-style: none;
  margin-left: 0;
}

.o-list-inline__item {
  display: inline-block;
}

/* ==========================================================================
   #MAIN-CONTENT
   ========================================================================== */
/**
 * Page-level object for main page content
 */
@media (min-width: 768px) {
  .o-main-content {
    padding-top: 30vw;
  }
  .home .o-main-content {
    padding-top: 23vw;
  }
}
@media (min-width: 1024px) {
  .o-main-content {
    padding-top: 30vw;
  }
  .home .o-main-content {
    padding-top: 22vw;
  }
}
@media (min-width: 1390px) {
  .o-main-content {
    padding-top: 40rem;
  }
  .home .o-main-content {
    padding-top: 30rem;
  }
}

/* ==========================================================================
   #MEDIA
   ========================================================================== */
/**
 * Place any image- and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 */
.o-media {
  display: block;
}
.o-media::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

.o-media__img {
  float: left;
  margin-right: 1rem;
}
.o-media__img > img {
  display: block;
}

.o-media__body {
  display: block;
  overflow: hidden;
}
.o-media__body,
.o-media__body > :last-child {
  margin-bottom: 0;
}

/* Reversed media objects
   ========================================================================== */
.o-media--reverse > .o-media__img {
  float: right;
  margin-left: 1rem;
  margin-right: 0;
}

/* Gutterless media objects
   ========================================================================== */
.o-media--flush > .o-media__img {
  margin-left: 0;
  margin-right: 0;
}

/* ==========================================================================
   #TABLE
   ========================================================================== */
/**
 * A simple object for manipulating the structure of HTML `table`s.
 */
.o-table {
  width: 100%;
}

/* Equal-width table cells.
   ========================================================================== */
/**
 * `table-layout: fixed` forces all cells within a table to occupy the same
 * width as each other. This also has performance benefits: because the browser
 * does not need to (re)calculate cell dimensions based on content it discovers,
 * the table can be rendered very quickly. Further reading:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
 */
.o-table--fixed {
  table-layout: fixed;
}

.o-title {
  font-weight: normal;
  text-align: center;
  margin: 4rem 0;
  text-transform: uppercase;
}

/* ==========================================================================
   #WRAPPER
   ========================================================================== */
/**
 * Page-level constraining and wrapping elements.
 */
.o-wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: 137rem;
}
.o-wrapper::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}
@media (max-width: 1389.98px) {
  .o-wrapper {
    margin-right: 1rem;
    margin-left: 1rem;
  }
}

/* stylelint-disable */
/* ==========================================================================
   #COLORS
   ========================================================================== */
.has-white-color {
  color: #fff;
}

.has-white-background-color {
  background-color: #fff;
}

.has-black-color {
  color: #000;
}

.has-black-background-color {
  background-color: #000;
}

.has-very-light-grey-color {
  color: #f5f5f5;
}

.has-very-light-grey-background-color {
  background-color: #f5f5f5;
}

.has-light-grey-color {
  color: #999;
}

.has-light-grey-background-color {
  background-color: #999;
}

.has-medium-grey-color {
  color: #666;
}

.has-medium-grey-background-color {
  background-color: #666;
}

.has-dark-grey-color {
  color: #333;
}

.has-dark-grey-background-color {
  background-color: #333;
}

/* ==========================================================================
   #ACCORDIONS
   ========================================================================== */
@media (max-width: 1023.98px) {
  .b-accordions {
    column-width: auto !important;
  }
}

.b-accordions--columns {
  display: grid;
  grid-column-gap: 2rem;
}
@media (min-width: 1024px) {
  .b-accordions--columns {
    grid-template-columns: repeat(2, 1fr);
  }
}

.b-accordions__item {
  border-bottom: 2px solid #000;
  margin-bottom: 1rem;
}
.b-accordions__item h4 {
  margin-bottom: 0;
}

.b-accordions__item-title {
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  padding-top: 2rem;
  font-size: 3rem;
  font-weight: 500;
  line-height: 145%;
  border-top: 3px solid #999;
}
@media (min-width: 768px) {
  .b-accordions__item-title {
    font-size: 4rem;
    line-height: 165%;
  }
}

.b-accordions__item:first-child .b-accordions__item-title {
  margin-top: 0;
  padding-top: 0;
  border-top: 0;
  border-top: none;
}

.b-accordions__item-toggle {
  margin-bottom: 0.5rem;
  padding: 0;
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  font: inherit;
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 136%;
  text-align: left;
  appearance: none;
  cursor: pointer;
}

.b-accordions__item-content {
  height: 0;
  overflow: hidden;
  transition: all 0.3s;
}
.no-js .b-accordions__item-content {
  height: auto;
}

.b-accordions__item-content-inner {
  padding-bottom: 3rem;
}

.b-block-wrapper {
  position: relative;
}
.b-block-wrapper.is-stretched {
  position: relative;
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);
}

.b-buy-ticket__link {
  display: flex;
  width: 11.3rem;
  height: 11.3rem;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  border: 2px solid;
  color: #000;
  background: #fff;
  text-decoration: none;
  text-transform: uppercase;
  margin: 5rem auto 0;
}

/* ==========================================================================
   #ACCORDIONS
   ========================================================================== */
.b-idioms {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 6rem;
  border-bottom: 2px solid;
}

.b-idioms__image-wrapper {
  width: 100%;
}

.b-idioms__image {
  width: 100%;
  margin-bottom: 2rem;
}

.b-idioms__image img {
  width: 100%;
  margin: 0 auto;
  display: block;
}
@media (min-width: 1024px) {
  .b-idioms__image img {
    width: 66%;
  }
}

/* ==========================================================================
   #NEWSLETTER FORM
   ========================================================================== */
.b-newsletter-form input {
  background: transparent;
  border: 0;
  padding: 0;
  font-size: 1.4rem;
  font-family: "ABCCamera_Regular", sans-serif;
  color: #000;
  font-weight: normal;
  /* stylelint-disable */
  /* stylelint-enable */
}
@media (max-width: 1023.98px) {
  .is_bg_image .b-newsletter-form input {
    color: #fff;
  }
}
.b-newsletter-form input[type=email] {
  width: 100%;
}
.b-newsletter-form input[type=submit] {
  cursor: pointer;
}

/* ==========================================================================
   #PAWILON CALENDAR
   #DEPENDS ON: THE EVENTS CALENDAR PRO PLUGIN
   ========================================================================== */
/* stylelint-disable */
.b-pawilon-calendar {
  width: 100%;
  position: relative;
}
.b-pawilon-calendar .tribe-events-widget-shortcode__header-title {
  display: none;
}
.b-pawilon-calendar .tribe-events {
  font-size: 3.4rem;
  text-transform: uppercase;
  padding: 0 !important;
}
.b-pawilon-calendar .tribe-events .tribe-events-header {
  border-bottom: 2px solid currentColor;
  margin-bottom: 1.5rem !important;
  padding-inline: 0px;
  margin-inline: auto;
  max-width: 100%;
}
.b-pawilon-calendar .tribe-events .tribe-events-calendar-month__day .tribe-events-calendar-month__day-cell {
  border-radius: 100%;
  justify-content: center;
  top: 50% !important;
  left: 50%;
  width: 90%;
  height: 90%;
  transform: translate(-50%, -50%);
}
@media (min-width: 768px) {
  .b-pawilon-calendar .tribe-events .tribe-events-calendar-month__day .tribe-events-calendar-month__day-cell {
    width: 66%;
    height: 66%;
  }
}
.b-pawilon-calendar .tribe-events .tribe-events-calendar-month__day .tribe-events-calendar-month__day-cell h3 {
  font-size: 2rem;
  position: relative;
  top: 0.1rem;
  color: #666;
}
@media (min-width: 768px) {
  .b-pawilon-calendar .tribe-events .tribe-events-calendar-month__day .tribe-events-calendar-month__day-cell h3 {
    font-size: 3.4rem;
  }
}
.b-pawilon-calendar .tribe-events .tribe-events-calendar-month__day .tribe-events-calendar-month__day-cell:focus {
  background: transparent !important;
}
.b-pawilon-calendar .tribe-events .tribe-events-calendar-month__day .tribe-events-calendar-month__day-cell:focus h3 {
  color: #000;
}
.b-pawilon-calendar .tribe-events .tribe-events-calendar-month__day .tribe-events-calendar-month__day-cell--selected {
  border-color: #000;
}
.b-pawilon-calendar .tribe-events .tribe-events-calendar-month__day .tribe-events-calendar-month__day-cell--selected:focus {
  border: 2px solid #000;
}
@media (min-width: 768px) {
  .b-pawilon-calendar .tribe-events .tribe-events-calendar-month__day .tribe-events-calendar-month__day-cell--selected:focus {
    border-width: 4px;
  }
}
.b-pawilon-calendar .tribe-events .tribe-events-calendar-month__day .tribe-events-calendar-month__day-cell--selected:focus .tribe-events-calendar-month__mobile-events-icon.tribe-events-calendar-month__mobile-events-icon--event,
.b-pawilon-calendar .tribe-events .tribe-events-calendar-month__day .tribe-events-calendar-month__day-cell--selected:focus .tribe-events-calendar-month__mobile-events-icon.tribe-events-calendar-month__mobile-events-icon--featured {
  border: 0;
}
.b-pawilon-calendar .tribe-events .tribe-events-calendar-month__day--current .tribe-events-calendar-month__day-cell {
  background: #000;
  border: 2px solid #000;
}
@media (min-width: 768px) {
  .b-pawilon-calendar .tribe-events .tribe-events-calendar-month__day--current .tribe-events-calendar-month__day-cell {
    border-width: 4px;
  }
}
.b-pawilon-calendar .tribe-events .tribe-events-calendar-month__day--current .tribe-events-calendar-month__day-cell h3 {
  color: #fff;
}
.b-pawilon-calendar .tribe-events .tribe-events-calendar-month__day--other-month {
  opacity: 0.5;
}
.b-pawilon-calendar .tribe-events .tribe-events-calendar-month__day:hover .tribe-events-calendar-month__day-cell {
  background: #000 !important;
}
.b-pawilon-calendar .tribe-events .tribe-events-calendar-month__day:hover .tribe-events-calendar-month__day-cell h3 {
  color: #fff;
}
.b-pawilon-calendar .tribe-events .tribe-events-calendar-month__day:hover .tribe-events-calendar-month__day-cell .tribe-events-calendar-month__mobile-events-icon {
  border-color: transparent !important;
}
.b-pawilon-calendar .tribe-events .tribe-events-calendar-month__day .tribe-events-calendar-month__mobile-events-icon.tribe-events-calendar-month__mobile-events-icon--featured .tribe-common-c-svgicon--featured {
  display: none;
}
.b-pawilon-calendar .tribe-events .tribe-events-calendar-month__day--no-events .tribe-events-calendar-month__mobile-events-icon {
  display: none;
}
.b-pawilon-calendar .tribe-events .tribe-events-calendar-month__day--has-events .tribe-events-calendar-month__mobile-events-icon {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: transparent;
  border-radius: 100%;
  border: 2px solid #666;
}
@media (min-width: 768px) {
  .b-pawilon-calendar .tribe-events .tribe-events-calendar-month__day--has-events .tribe-events-calendar-month__mobile-events-icon {
    border-width: 4px;
  }
}
.b-pawilon-calendar .tribe-events .tribe-events-calendar-month__day--has-multiday-events:not(.tribe-events-calendar-month__day--has-events) .tribe-events-calendar-month__mobile-events-icon {
  display: block;
  width: 6px;
  height: 6px;
  position: absolute;
  background-color: #000;
  border: 0;
  border-radius: 100%;
  bottom: 0;
  transition: all 0.1s;
}
.b-pawilon-calendar .tribe-events .tribe-events-calendar-month__day--has-multiday-events:not(.tribe-events-calendar-month__day--has-events).tribe-events-calendar-month__day--current .tribe-events-calendar-month__day-cell .tribe-events-calendar-month__mobile-events-icon {
  bottom: -1.4rem;
}
.b-pawilon-calendar .tribe-events .tribe-events-calendar-month__day--has-multiday-events:not(.tribe-events-calendar-month__day--has-events) .tribe-events-calendar-month__day-cell:focus .tribe-events-calendar-month__mobile-events-icon {
  bottom: -1.4rem;
}
.b-pawilon-calendar .tribe-common .tribe-common-l-container {
  padding-inline: 0;
}
.b-pawilon-calendar .tribe-common-c-svgicon--pawilon-arrow {
  width: 2.2rem;
  position: relative;
  top: -0.2rem;
}
.b-pawilon-calendar .tribe-events-calendar-month__header-column h3.tribe-events-calendar-month__header-column-title {
  text-align: center;
  font-size: 2rem;
  font-family: "ABCCamera_Bold", sans-serif;
}
.b-pawilon-calendar .tribe-events-c-top-bar__nav-list-date {
  font-size: 2rem;
}
@media (min-width: 768px) {
  .b-pawilon-calendar .tribe-events-c-top-bar__nav-list-date {
    font-size: 3.5rem;
  }
}
.b-pawilon-calendar .tribe-events-widget-events-month__view-more {
  display: none;
}
.b-pawilon-calendar .tribe-events-calendar-month-mobile-events__mobile-day-today .tribe-events-calendar__pawilon-events-other-day {
  display: none;
}
.b-pawilon-calendar .tribe-events-calendar__pawilon-event {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  text-transform: initial;
}
.b-pawilon-calendar .tribe-events-calendar__pawilon-event > * {
  font-size: 1.3rem;
  line-height: 1.5;
}
@media (min-width: 768px) {
  .b-pawilon-calendar .tribe-events-calendar__pawilon-event > * {
    font-size: 1.8rem;
  }
}
.b-pawilon-calendar .tribe-events-calendar__pawilon-event .tribe-events-calendar__pawilon-date {
  width: 20%;
  flex-shrink: 0;
}
@media (min-width: 768px) {
  .b-pawilon-calendar .tribe-events-calendar__pawilon-event .tribe-events-calendar__pawilon-date {
    width: 18%;
  }
}
.b-pawilon-calendar .tribe-events-calendar__pawilon-event .tribe-events-calendar__pawilon-date > * {
  font-weight: normal;
}
.b-pawilon-calendar .tribe-events-calendar__pawilon-event .tribe-events-calendar__pawilon-title {
  margin-right: auto;
  max-width: 65%;
}
.b-pawilon-calendar .tribe-events-calendar__pawilon-event .tribe-events-calendar__pawilon-title > * {
  font-family: "ABCCamera_Bold", sans-serif;
}
.b-pawilon-calendar .tribe-events-calendar__pawilon-event .tribe-events-calendar__pawilon-ticket {
  width: 18%;
  flex-shrink: 0;
  text-align: right;
}
.b-pawilon-calendar .tribe-events-calendar__pawilon-event .tribe-events-calendar__pawilon-ticket a {
  font-family: "ABCCamera_Bold", sans-serif;
}
.b-pawilon-calendar .tribe-events-calendar__pawilon-day-header {
  text-align: center;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  border-bottom: 2px solid;
  line-height: initial;
}
.b-pawilon-calendar .tribe-events-calendar__pawilon-day-header:not(:first-child) {
  display: none;
}
.b-pawilon-calendar .tribe-events-calendar__pawilon-day-empty {
  font-size: 1.8rem;
  text-align: center;
}
.b-pawilon-calendar .tribe-events-calendar-month-mobile-events__mobile-day--show {
  opacity: 0;
  animation: fadeIn 1s;
  animation-fill-mode: forwards;
  overflow: hidden;
}
.b-pawilon-calendar .tribe-events-calendar__pawilon-no-events .tribe-events-c-messages__message {
  justify-content: center;
  font-size: 1.8rem;
  line-height: 2.7rem;
}
.b-pawilon-calendar .tribe-events-calendar__pawilon-no-events .tribe-events-c-messages__message svg {
  display: none;
}

/* ==========================================================================
   #PAWILON LIST
   #DEPENDS ON: THE EVENTS CALENDAR PRO PLUGIN
   ========================================================================== */
/* stylelint-disable */
.b-pawilon-list {
  font-family: "ABCCamera_Regular", sans-serif;
  /* stylelint-enable */
}
.b-pawilon-list .tribe-events-l-container {
  padding: 0 !important;
  min-height: 0 !important;
}
.b-pawilon-list nav.tribe-events-calendar-list-nav {
  width: 95vw;
  max-width: 139rem;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  padding-top: 0;
  top: 0;
}
@media (min-width: 768px) {
  .b-pawilon-list nav.tribe-events-calendar-list-nav {
    position: relative;
  }
}
.b-pawilon-list .tribe-events-calendar-list__month-separator,
.b-pawilon-list .tribe-events-calendar-list__tag-name {
  font-size: 2.5rem;
  font-weight: normal;
  text-transform: uppercase;
  text-align: center;
}
.b-pawilon-list .tribe-events-calendar-list__tag-name {
  padding-top: 6rem;
  padding-bottom: 2rem;
  margin-bottom: 6rem;
}
@media (min-width: 768px) {
  .b-pawilon-list .tribe-events-calendar-list__tag-name {
    padding-top: 0;
    border-bottom: 2px solid #000;
  }
}
.b-pawilon-list .tribe-events-calendar__pawilon-event-list {
  padding-bottom: 0.8rem;
  margin-bottom: 2rem;
  border-bottom: 2px solid #000;
}
.is_bg_image .b-pawilon-list .tribe-events-calendar__pawilon-event-list {
  border-bottom-color: #fff;
}
@media (max-width: 767.98px) {
  .b-pawilon-list .tribe-events-calendar__pawilon-event-list {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
  }
}
@media (min-width: 768px) {
  .b-pawilon-list .tribe-events-calendar__pawilon-event-list {
    display: grid;
    grid-template-columns: 25% 60% 15%;
  }
}
.b-pawilon-list .tribe-events-calendar__pawilon-events-list + h2 {
  margin-top: 8rem;
}
.b-pawilon-list .tribe-events-calendar__pawilon-list-date {
  font-size: 4rem;
  line-height: 1;
}
@media (max-width: 767.98px) {
  .b-pawilon-list .tribe-events-calendar__pawilon-list-date {
    width: 35%;
    transform: translateY(3px);
  }
}
@media (min-width: 768px) {
  .b-pawilon-list .tribe-events-calendar__pawilon-list-date {
    grid-row: span 2;
    display: flex;
    align-items: flex-end;
    position: relative;
    top: 0.25rem;
  }
}
.b-pawilon-list .tribe-events-calendar__pawilon-list-title {
  margin: 0;
}
@media (max-width: 767.98px) {
  .b-pawilon-list .tribe-events-calendar__pawilon-list-title {
    width: 60%;
  }
}
@media (min-width: 768px) {
  .b-pawilon-list .tribe-events-calendar__pawilon-list-title {
    grid-column: 2/3;
    grid-row: span 2;
    align-self: flex-end;
  }
}
.b-pawilon-list .tribe-events-calendar__pawilon-list-title-link {
  font-size: 2rem;
  line-height: 1;
  color: #000;
  text-decoration: none;
  font-weight: bold;
}
.is_bg_image .b-pawilon-list .tribe-events-calendar__pawilon-list-title-link {
  color: #fff;
}
.b-pawilon-list .tribe-events-calendar__pawilon-list-tags {
  text-align: left;
  margin-bottom: 0;
  list-style: none;
}
@media (max-width: 767.98px) {
  .b-pawilon-list .tribe-events-calendar__pawilon-list-tags {
    margin-top: 3rem;
    width: 50%;
    margin-left: auto;
    order: 10;
  }
}
@media (min-width: 768px) {
  .b-pawilon-list .tribe-events-calendar__pawilon-list-tags {
    font-size: 1.2rem;
    line-height: 1.8rem;
    grid-column: 3/-1;
    align-self: flex-end;
    margin-left: 1rem;
    grid-row-start: 1;
    grid-row-end: span 2;
  }
}
.b-pawilon-list .tribe-events-calendar__pawilon-list-tags a {
  text-decoration: none;
  color: #000;
}
.is_bg_image .b-pawilon-list .tribe-events-calendar__pawilon-list-tags a {
  color: #fff;
}
.b-pawilon-list .tribe-events-calendar__pawilon-list-tag {
  margin-bottom: 0;
}
.b-pawilon-list .tribe-events-calendar__pawilon-list-time-start {
  display: none;
}
@media (max-width: 767.98px) {
  .b-pawilon-list .tribe-events-calendar__pawilon-list-time-start {
    width: 40%;
  }
}
@media (min-width: 768px) {
  .b-pawilon-list .tribe-events-calendar__pawilon-list-time-start {
    grid-column: 1/2;
  }
}
.b-pawilon-list .tribe-events-calendar__nav-common, .b-pawilon-list .tribe-events-calendar__nav-common button {
  width: 5rem;
  background: none;
  border: 0;
}
@media (min-width: 768px) {
  .b-pawilon-list .tribe-events-calendar__nav-common, .b-pawilon-list .tribe-events-calendar__nav-common button {
    width: 26rem;
  }
}
.b-pawilon-list .tribe-events-calendar__nav-prev {
  transform: rotate(90deg);
}
.b-pawilon-list .tribe-events-calendar__nav-prev--disabled {
  opacity: 0.25;
}
.b-pawilon-list .tribe-events-calendar__nav-next {
  transform: rotate(270deg);
}
.b-pawilon-list .tribe-events-calendar__nav-next--disabled {
  opacity: 0.25;
}
.b-pawilon-list .tribe-events-calendar__pawilon-event-image {
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .b-pawilon-list .tribe-events-calendar__pawilon-event-image {
    padding-top: 0.5rem;
    border-top: 2px solid;
  }
}
.b-pawilon-list.is_featured .tribe-events-header--has-no-events {
  display: none;
}
.b-pawilon-list.is_featured .tribe-events-calendar-list__month-separator,
.b-pawilon-list.is_featured .tribe-events-calendar-list__tag-name {
  display: none;
}
@media (max-width: 767.98px) {
  .b-pawilon-list.is_featured .tribe-events-calendar__pawilon-list-tags {
    width: 50%;
  }
  .b-pawilon-list.is_featured .tribe-events-calendar__pawilon-list-date {
    width: 100%;
    font-size: 10rem;
  }
  .b-pawilon-list.is_featured .tribe-events-calendar__pawilon-list-title {
    width: 100%;
    margin-top: 8rem;
  }
  .is-safari .b-pawilon-list.is_featured .tribe-events-calendar__pawilon-list-title {
    letter-spacing: -2px;
  }
  .b-pawilon-list.is_featured .tribe-events-calendar__pawilon-list-title a {
    font-size: 3.3rem;
  }
}
.home .b-pawilon-list.is_featured {
  margin-top: 1.6rem;
}

/* ==========================================================================
   #CUSTOM SAMPLE BLOCK
   ========================================================================== */
.b-sample-block {
  font-size: 3rem;
}

.b-side-label {
  font-size: 1.4rem;
  width: calc(100vw - 2.6rem);
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  max-width: 137rem;
}
@media (max-width: 1023.98px) {
  .b-side-label {
    display: none;
  }
}
.is-wrapped .b-side-label {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 0;
}

.b-side-label__rotated {
  transform: rotate(90deg);
  display: inline-block;
  transform-origin: bottom left;
  margin-bottom: 0;
}
.b-side-label__rotated span {
  display: block;
  transform: rotate(180deg);
}
.is-wrapped .b-side-label__rotated {
  transform: rotate(90deg) translateX(-50%);
}

.b-team {
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
}
@media (min-width: 768px) {
  .b-team {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    margin-bottom: 15rem;
  }
}

.b-team__text {
  font-size: 1.2rem;
  line-height: 1.6rem;
  padding-inline: 2rem;
}
@media (min-width: 768px) {
  .b-team__text {
    padding-inline: 0;
  }
}

.b-team .b-team__name {
  font-size: 4rem;
  line-height: 3.4rem;
  text-transform: none;
  margin-top: 0;
  border-bottom: 0;
  padding-bottom: 0;
  text-align: inherit;
}

.b-team__photo-wrapper {
  padding-top: 125%;
  margin-bottom: 3rem;
}
.b-team__photo-wrapper figcaption {
  position: absolute;
  font-size: 1rem;
  left: 0;
}
.is-reversed .b-team__photo-wrapper figcaption {
  left: auto;
  right: 0;
}

@media (min-width: 768px) {
  .b-team.is-reversed .b-team__photo-wrapper {
    grid-column: 2;
  }

  .b-team.is-reversed .b-team__text {
    grid-row: 1;
    grid-column: 1;
  }
}
/* stylelint-enable */
/* ==========================================================================
   #BUTTONS
   ========================================================================== */
/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */
/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */
.c-btn {
  display: inline-block;
  /* [1] */
  vertical-align: middle;
  /* [2] */
  font: inherit;
  /* [3] */
  text-align: center;
  /* [4] */
  margin: 0;
  /* [4] */
  cursor: pointer;
  /* [5] */
  padding: 0.5rem 1rem;
  transition: all 0.3s;
  border-radius: 3px;
}

/* Style variants
   ========================================================================== */
.c-btn--primary {
  background-color: #666;
}
.c-btn--primary, .c-btn--primary:hover, .c-btn--primary:active, .c-btn--primary:focus {
  text-decoration: none;
  /* [4] */
  color: #fff;
}
.c-btn--primary:hover, .c-btn--primary:focus {
  background-color: #999;
}

/* Size variants
   ========================================================================== */
.c-btn--small {
  padding: 0.25rem 0.5rem;
}

.c-btn--large {
  padding: 0.75rem 1.5rem;
}

/* ==========================================================================
   #COMMENT FORM
   Here we are using default HTML structure and classes,
   to create custom ones use comment_form_defaults filter
   ========================================================================== */
.c-comment-form {
  margin-top: 2em;
}
.c-comment-form label {
  display: block;
}
.c-comment-form textarea {
  max-width: 100%;
}

.comment-reply-title {
  margin-bottom: 0.25em;
}

/* ==========================================================================
   #COMMENT
   ========================================================================== */
.c-comment {
  margin-bottom: 1em;
}

.c-comment__author {
  font-size: 1rem;
  margin-bottom: 0.25em;
}

.c-comment__moderation {
  font-style: italic;
  margin-bottom: 0.5em;
}

/* ==========================================================================
   #FOOTER
   ========================================================================== */
/* stylelint-disable */
.c-footer {
  padding-top: 1rem;
  position: relative;
  margin-inline: 0;
  margin-top: 12rem;
  margin-bottom: 0;
  background: #f5f5f5;
}
@media (max-width: 1023.98px) {
  .is_bg_image .c-footer {
    background: transparent;
    color: #fff;
  }
}
@media (min-width: 768px) {
  .c-footer {
    margin-top: 10rem;
    margin-inline: 1rem;
  }
}
@media (min-width: 1390px) {
  .c-footer {
    margin-top: 10rem;
    margin-inline: auto;
    max-width: 137rem;
  }
}
.c-footer::before {
  content: "";
  background: #f5f5f5;
  position: absolute;
  top: -3rem;
  height: 100vh;
  width: 100vw;
  z-index: 0;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 1023.98px) {
  .is_bg_image .c-footer::before {
    background: transparent;
  }
}
.c-footer::after {
  content: "";
  height: 2px;
  width: 100%;
  background-color: #000;
  display: block;
  position: absolute;
  z-index: 1;
  top: -2px;
}
@media (max-width: 767.98px) {
  .c-footer::after {
    width: calc(100% - 2rem);
    left: 1rem;
  }
}
@media (max-width: 1023.98px) {
  .is_bg_image .c-footer::after {
    background-color: #fff;
  }
}
.c-footer a {
  text-decoration: none;
}
@media (max-width: 1023.98px) {
  .is_bg_image .c-footer a {
    color: #fff;
  }
}
.c-footer a:hover {
  text-decoration: underline;
}
.c-footer p {
  margin-bottom: 0;
}
.c-footer .wp-block-columns {
  row-gap: 0;
  column-gap: 2.75rem;
  margin-inline: 1rem;
  position: relative;
}
.c-footer .wp-block-column {
  margin-left: 0 !important;
}
@media (max-width: 767.98px) {
  .c-footer .wp-block-column {
    margin-bottom: 1rem;
  }
}

.c-footer__mobile-image {
  margin-left: -1rem;
  margin-right: -1rem;
  margin-bottom: 0;
  position: relative;
}
.c-footer__mobile-image img {
  width: 100%;
  height: auto;
}
@media (min-width: 768px) {
  .c-footer__mobile-image {
    display: none;
  }
}

.c-footer__mobile-logo {
  position: relative;
  width: calc(100% - 2rem);
  left: 1rem;
  margin-top: 2rem;
}
.c-footer__mobile-logo svg path {
  fill: #000;
}
@media (min-width: 768px) {
  .c-footer__mobile-logo {
    display: none;
  }
}
@media (max-width: 1023.98px) {
  .is_bg_image .c-footer__mobile-logo svg path {
    fill: #fff;
  }
}

/* ==========================================================================
   #HEADER
   ========================================================================== */
/* stylelint-disable */
.c-header {
  border-bottom: 2px solid #000;
  position: fixed;
  width: calc(100% - 2rem);
  z-index: 100;
  transform: translateY(4rem);
}
@media (min-width: 768px) {
  .c-header {
    background: #fcfcfc;
  }
}
@media (min-width: 1390px) {
  .c-header {
    width: 100%;
    left: 50%;
    transform: translate(-50%, 4rem);
  }
}
@media (max-width: 1023.98px) {
  .c-header {
    height: 4rem;
  }
}
.is_bg_image .c-header {
  border-color: #fff;
  background-color: transparent;
}

.c-header__inner {
  display: flex;
  justify-content: space-between;
  background-color: #fcfcfc;
  position: relative;
  max-height: calc(4rem - 1px);
}
.is_bg_image .c-header__inner {
  background-color: transparent !important;
}
.c-header__inner::before {
  content: "";
  background: #fcfcfc;
  width: 100vw;
  height: calc(4rem - 1px);
  position: absolute;
  z-index: -1;
  top: -1px;
  transform: translateX(-50%);
  left: 50%;
}
.is_bg_image .c-header__inner::before {
  background-color: rgba(0, 0, 0, 0.5);
}

/* ==========================================================================
   #HOME MESSAGE
   ========================================================================== */
.c-home-message {
  font-size: 1.8rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid currentColor;
}

.c-list-nav {
  width: 95vw;
  max-width: 139rem;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  padding-top: 0;
}
@media (min-width: 768px) {
  .c-list-nav {
    position: relative;
  }
}

.c-list-nav__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  list-style: none;
  margin: 0;
}

/* ==========================================================================
   #LOGO
   ========================================================================== */
/* stylelint-disable */
.c-logo {
  display: none;
}
@media (min-width: 768px) {
  .c-logo {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
  }
  .c-logo .is-hidden {
    animation: fadeOut 0.1s;
    animation-fill-mode: forwards;
  }
}
.is_bg_image .c-logo path {
  fill: #fff;
}

.c-logo__part--p {
  width: 16%;
}
.c-logo__part--awilon {
  width: 83.2%;
  margin-bottom: 9%;
  transform: rotateY(0deg);
  transform-origin: left;
}

/* ==========================================================================
   #MAIN NAV
   ========================================================================== */
/* stylelint-disable */
.c-main-nav__wrapper {
  position: relative;
  z-index: 100;
}
@media (min-width: 1024px) {
  .is_bg_image .c-main-nav__wrapper * {
    color: #fff !important;
  }
  .is_bg_image .c-main-nav__wrapper * *, .is_bg_image .c-main-nav__wrapper * {
    background-color: transparent !important;
  }
}

.c-main-nav {
  display: flex;
  margin: 0;
  transition: all 0.33s;
  z-index: 10;
}
@media (max-width: 1023.98px) {
  .c-main-nav {
    flex-direction: column;
    position: absolute;
    top: 3.7rem;
    left: -1rem;
    background: #fff;
    width: 100vw;
    transform: translateX(-100%);
    height: 100vh;
  }
}

.c-main-nav__toggle {
  background: none;
  border: 0;
  font-size: 2.5rem;
  padding: 0;
  margin-top: 0.5rem;
  display: block;
  color: #000;
}
@media (min-width: 1024px) {
  .c-main-nav__toggle {
    display: none;
  }
}
.is_bg_image .c-main-nav__toggle {
  color: #fff;
}

@media (max-width: 1023.98px) {
  .is-open .c-main-nav {
    transform: translateX(0%);
    overflow-y: auto;
  }
  .is-open .c-main-nav::before {
    content: "";
    width: 100%;
    position: fixed;
    top: 0;
    background: white;
    z-index: -1;
  }
  .is-open .c-main-nav::after {
    content: "";
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0) 100%);
    width: 10rem;
    height: 100vh;
    position: absolute;
    top: 0;
    right: -10rem;
  }
}

.c-main-nav__item {
  list-style: none;
  margin: 0 1em 0 0;
  padding: 0;
  position: relative;
}
@media (max-width: 1023.98px) {
  .c-main-nav__item {
    border-bottom: 2px solid #000;
    font-size: 3rem;
    text-align: center;
    text-transform: uppercase;
    margin: 0 0.75em 0 0.75em;
    -webkit-tap-highlight-color: transparent !important;
  }
}
.c-main-nav__item--footer {
  margin: 6rem 2rem;
  list-style: none;
}

.c-main-nav__link {
  color: #000;
  display: block;
  padding: 0.7em 1em 0.5em;
  text-decoration: none;
  line-height: 1;
}
@media (min-width: 1024px) {
  .c-main-nav__link {
    font-family: "ABCCamera_Regular", sans-serif;
    padding: 0.7em 1em 0.6em 0;
    line-height: 1.4;
  }
}

.c-main-nav__expand {
  display: none;
}

.c-main-nav__dropdown {
  display: none;
}
@media (min-width: 1024px) {
  .c-main-nav__dropdown {
    flex-direction: column;
    margin: 0;
    position: absolute;
    z-index: 99;
    border-width: 2px 2px 2px;
    border-color: #fff #000 #000;
    border-style: solid;
    left: -2rem;
    padding: 0 1rem 0.5rem;
    background: #fff;
    text-align: left;
    min-width: max-content;
  }
  .is_bg_image .c-main-nav__dropdown {
    border-width: 3px 3px 3px;
    border-color: #000 #fff #fff;
    background: rgba(0, 0, 0, 0.5) !important;
  }
  .c-main-nav__dropdown.is-visible {
    display: flex;
  }
}
.c-main-nav__item.is-expanded .c-main-nav__dropdown {
  display: block;
}
.c-main-nav__dropdown + .c-main-nav__expand {
  position: absolute;
  right: 0;
  top: 3.25rem;
  background: transparent;
  border: 0;
  width: 2.4rem;
  height: 2.4rem;
  transform: translateY(-50%);
}
@media (max-width: 1023.98px) {
  .c-main-nav__dropdown + .c-main-nav__expand {
    display: block;
  }
}
.c-main-nav__dropdown + .c-main-nav__expand::before, .c-main-nav__dropdown + .c-main-nav__expand::after {
  content: "";
  background: #000;
  height: 2px;
  width: 100%;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.c-main-nav__dropdown + .c-main-nav__expand::after {
  transform: translate(-50%, -50%) rotate(90deg);
  transform-origin: center;
}
.c-main-nav__item.is-expanded .c-main-nav__dropdown + .c-main-nav__expand::after {
  animation: collapseExpandToggle 0.25s;
  animation-fill-mode: forwards;
}

.c-main-nav__subitem {
  list-style: none;
  margin: 0;
  min-width: 20rem;
}
@supports (min-width: max-content) {
  .c-main-nav__subitem {
    min-width: max-content;
  }
}
@media (max-width: 1023.98px) {
  .c-main-nav__subitem {
    font-size: 1.6rem;
    text-transform: none;
  }
}

.c-main-nav__sublink {
  color: #000;
  background: #fff;
  display: block;
  padding: 0.5em;
  text-decoration: none;
}
@media (max-width: 1023.98px) {
  .c-main-nav__sublink {
    padding-bottom: 2rem;
  }
  .c-main-nav__sublink:first-child {
    padding-top: 0;
  }
}

.current-menu-item .c-main-nav__sublink {
  border-bottom-color: #000;
}

/* ==========================================================================
   #MAIN CONTENT
   ========================================================================== */
.c-main {
  position: relative;
  min-height: 50vh;
  font-size: 1.8rem;
  font-weight: normal;
  line-height: 2.2rem;
  margin-top: 8rem;
}
@media (min-width: 768px) {
  .c-main {
    margin-top: 4rem;
  }
}

.navigation.pagination {
  margin-top: 6rem;
  text-align: center;
}
.navigation.pagination a,
.navigation.pagination span {
  color: #000;
  text-decoration: none;
  padding: 0 1rem;
}
.navigation.pagination span {
  opacity: 0.25;
}

/* ==========================================================================
   #PAWILON TV
   ========================================================================== */
.c-pawilon-tv {
  background-color: #fded5f;
  min-height: 4rem;
  position: fixed;
  display: flex;
  align-items: center;
  z-index: 11;
  width: 100%;
  /* stylelint-disable */
  /* stylelint-enable */
}

.c-pawilon-tv__init-bar {
  display: flex;
  justify-content: center;
  width: 100%;
  cursor: pointer;
}
.c-pawilon-tv__init-bar::after {
  display: none !important;
}
@media (min-width: 768px) {
  .c-pawilon-tv__init-bar {
    justify-content: space-between;
  }
}

.c-pawilon-tv__init-bar-label {
  text-transform: uppercase;
}
@media (max-width: 767.98px) {
  .c-pawilon-tv__init-bar-label {
    width: 25%;
    text-align: center;
  }
}

.c-pawilon-tv__init-bar-teaser {
  margin-inline: auto;
  font-weight: bold;
  flex-grow: 1;
  text-align: center;
  overflow: hidden;
  position: relative;
}
.c-pawilon-tv__init-bar-teaser::before {
  content: "";
  display: block;
  background: #fded5f;
  background: linear-gradient(270deg, rgba(253, 237, 95, 0) 0%, #fded5f 100%);
  width: 5rem;
  height: 2rem;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.c-pawilon-tv__init-bar-teaser::after {
  content: "";
  display: block;
  background: #fded5f;
  background: linear-gradient(90deg, rgba(253, 237, 95, 0) 0%, #fded5f 100%);
  width: 5rem;
  height: 2rem;
  position: absolute;
  right: 0;
  top: 0;
}
@media (max-width: 767.98px) {
  .c-pawilon-tv__init-bar-teaser {
    display: none;
    width: 55%;
    margin-right: auto;
  }
  .c-pawilon-tv__init-bar-teaser span {
    display: block;
    width: max-content;
  }
}

@media (max-width: 767.98px) {
  .c-pawilon-tv__init-bar-cta {
    display: none;
    width: 20%;
  }
}

.c-pawilon-tv__content {
  display: none;
}
.c-pawilon-tv__content.is-content-expanded {
  display: block;
  width: 100%;
}

.c-pawilon-tv__close {
  background: none;
  border: none;
  display: block;
  width: 5rem;
  cursor: pointer;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
}

.c-pawilon-tv__video {
  display: block;
  /*
  overflow: hidden;

  @include bp-down(medium) {
    min-height: 50vw;
  }

  @include bp(medium) {
    max-height: calc(100vh - 8rem);
  }

  */
  width: calc(100vw - 2rem);
  margin-left: 1rem;
  position: relative;
}
@media (min-width: 768px) {
  .c-pawilon-tv__video {
    width: 100%;
    margin: 0;
  }
}

.c-pawilon-tv__play {
  width: 7.4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.c-pawilon-tv__panel {
  display: flex;
  justify-content: space-between;
  width: calc(100vw - 2rem);
  margin-left: 1rem;
  padding: 1rem 0;
  border-top: 2px solid #000;
}
@media (min-width: 768px) {
  .c-pawilon-tv__panel {
    width: 100%;
    margin: 0;
  }
}
@media (min-width: 768px) {
  .c-pawilon-tv__panel {
    padding: 1rem 1.5rem;
  }
}

.c-pawilon-tv__nav {
  width: 9.8rem;
  flex-shrink: 0;
}

.c-pawilon-tv__nav-icon {
  width: 4rem;
  display: block;
}
.c-pawilon-tv__nav-icon.is-hidden {
  display: none;
}

.c-pawilon-tv__desc-expanded {
  padding: 3rem 1rem;
  transition: all 0.3s;
  display: none;
}
.c-pawilon-tv__desc-expanded.is-visible {
  display: block;
}
@media (min-width: 768px) {
  .c-pawilon-tv__desc-expanded {
    padding: 3rem 14.3rem;
  }
}

.c-pawilon-tv__top-bar {
  height: 4rem;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 768px) {
  .c-pawilon-tv__top-bar {
    display: none;
  }
}

.c-pawilon-tv__label {
  display: none;
  font-size: 1.2rem;
}
@media (min-width: 768px) {
  .c-pawilon-tv__label {
    display: block;
  }
}

.c-pawilon-tv__next {
  display: none;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
}
@media (min-width: 768px) {
  .c-pawilon-tv__next {
    display: flex;
  }
}

.c-pawilon-tv__prev {
  display: none;
}
@media (min-width: 768px) {
  .c-pawilon-tv__prev {
    display: flex;
    flex-direction: column;
  }
}

.c-pawilon-tv__now {
  width: 50%;
  margin-right: auto;
  cursor: pointer;
}
@media (min-width: 768px) {
  .c-pawilon-tv__now {
    margin-left: 3rem;
  }
}

.c-pawilon-tv__desc {
  width: 20%;
}

.c-pawilon-tv__desc-button {
  background: transparent;
  border: 0;
  cursor: pointer;
}

.c-pawilon-tv__title {
  font-size: 1.8rem;
  font-weight: bold;
  text-transform: none;
  margin-bottom: 0;
}

.is-content-collapsed {
  display: none;
}

.is-content-expanded {
  min-height: 100vh !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

.c-pawilon-tv__more-wrapper {
  position: relative;
}

.c-pawilon-tv__more-posts {
  display: grid;
  gap: 3rem;
  margin-inline: 1.5rem;
  margin-top: 3rem;
  margin-bottom: 5rem;
  min-height: 20rem;
}
@media (min-width: 768px) {
  .c-pawilon-tv__more-posts {
    margin-inline: 8rem;
    grid-template-columns: repeat(5, 1fr);
  }
}
@media screen and (orientation: portrait) {
  .c-pawilon-tv__more-posts {
    min-height: 50vh;
  }
}

.c-pawilon-tv__more-title {
  text-align: left;
  font-size: 1.8rem;
  font-weight: normal;
}
@media (min-width: 768px) {
  .c-pawilon-tv__more-title {
    font-size: 1.4rem;
  }
}

.c-pawilon-tv__more-post {
  text-decoration: none;
}

.c-pawilon-tv__more-post svg {
  width: 5rem;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.c-pawilon-tv__more-button {
  display: none;
  border: 0;
  background: transparent;
  width: 3rem;
  position: absolute;
  /* stylelint-disable */
  top: calc((100vw - 280px) / 5 * 0.66 / 2);
  /* stylelint-enable */
  cursor: pointer;
  transform: translateY(-50%);
}
@media (min-width: 768px) {
  .c-pawilon-tv__more-button {
    display: block;
  }
}

.c-pawilon-tv__more-button.prev {
  left: 1rem;
  transform: translateY(-50%) rotate(180deg);
}

.c-pawilon-tv__more-button.next {
  right: 1rem;
}

.c-pawilon-tv__all {
  background: transparent;
  border: 0;
  margin: 0 auto;
  display: block;
  font-size: 1.4rem;
  padding-bottom: 2rem;
  font-family: inherit;
  cursor: pointer;
}

.c-pawilon-tv__more-loader {
  position: absolute;
  top: 4rem;
  left: 50%;
  transform: translateX(-50%);
}
.c-pawilon-tv__more-loader.is-hidden {
  display: none;
}

.c-pawilon-tv__close-bar {
  cursor: pointer;
  background: #fff;
  padding: 1.2rem 8rem;
  font-size: 1.4rem;
  text-align: center;
}
@media (min-width: 768px) {
  .c-pawilon-tv__close-bar {
    font-size: 1.8;
    text-align: left;
  }
}

.post-nav,
.post-nav button {
  width: 5rem;
  background: none;
  border: 0;
}
@media (min-width: 768px) {
  .post-nav,
.post-nav button {
    width: 26rem;
  }
}

.post-nav__prev {
  transform: rotate(90deg);
}
.post-nav__prev--disabled {
  opacity: 0.25;
}

.post-nav__next {
  transform: rotate(270deg);
}
.post-nav__next--disabled {
  opacity: 0.25;
}

.post-nav__wrapper {
  list-style: none;
  margin: 0;
  display: flex;
  justify-content: space-between;
  width: 95vw;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, calc(100% + 2.5rem));
}
@media (min-width: 768px) {
  .post-nav__wrapper {
    transform: translate(-50%, 25%);
  }
}
@media (min-width: 1024px) {
  .post-nav__wrapper {
    position: relative;
    bottom: auto;
  }
}

/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */
.c-post {
  margin-bottom: 2em;
}
.c-post::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

.alignnone {
  margin: 1em 1em 1em 0;
}

.aligncenter {
  margin: 1em auto;
}

.alignright {
  float: right;
  margin: 1em 0 1em 1em;
}

.alignleft {
  float: left;
  margin: 1em 1em 1em 0;
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  font-style: italic;
  margin-top: 0.5em;
}

/* ==========================================================================
   #PRE ARROW VISIBLE ON PREHEADER SCREEN
   ========================================================================== */
/* stylelint-disable */
.c-pre-arrow {
  position: absolute;
  bottom: 8rem;
  left: 3%;
  width: 17vw;
  cursor: pointer;
  z-index: 1;
}
@media (min-width: 1024px) {
  .c-pre-arrow {
    bottom: 2rem;
  }
}

/* ==========================================================================
   #PRE LOGO VISIBLE ON PREHEADER SCREEN
   ========================================================================== */
.c-pre-logo {
  position: absolute;
  z-index: 1;
  width: 90vw;
  top: 3rem;
  left: 50%;
  transform: translateX(-50%);
}

/* ==========================================================================
   #PREHEADER
   ========================================================================== */
/* stylelint-disable */
.c-preheader {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  background-color: #fff;
  background-size: cover;
  background-position: center;
  z-index: 2;
}
@media (min-width: 768px) {
  .c-preheader {
    background-size: 0%;
  }
}
.c-preheader.is-collapsed {
  height: 100vh;
  animation: collapsePreheader 0.5s;
  animation-fill-mode: forwards;
}
.c-preheader.is-hidden {
  display: none;
}
.c-preheader img {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 767.98px) {
  .c-preheader img {
    display: none;
  }
}

.c-preheader__label {
  position: absolute;
  bottom: 8rem;
  right: 4%;
  z-index: 1;
}
@media (min-width: 1024px) {
  .c-preheader__label {
    bottom: 2rem;
  }
}

.c-scroll-top {
  display: none;
  /* stylelint-disable */
  /* stylelint-enable */
}
@media (min-width: 1200px) {
  .c-scroll-top {
    display: block;
    position: absolute;
    right: 0;
    transform: rotate(180deg) translateY(100%);
    width: 26rem;
  }
  .events-single .c-scroll-top {
    transform: rotate(180deg) translateY(75%);
  }
}
.is_bg_image .c-scroll-top path {
  fill: #fff;
}

.c-scroll-top__wrapper {
  width: 98vw;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  z-index: 1;
}

/* ==========================================================================
   #SIDEHEADER @HEADER
   ========================================================================== */
/* stylelint-disable */
.c-sideheader {
  padding: 0.5rem 0;
  display: flex;
  gap: 2rem;
}
.is_bg_image .c-sideheader div path {
  fill: #fff;
}
.is_bg_image .c-sideheader div * {
  color: #fff;
}
.is_bg_image .c-sideheader div input {
  background: transparent;
  border: 1px solid #fff;
}
.is_bg_image .c-sideheader div input::placeholder {
  color: #fff;
}

.c-sideheader__lang {
  margin-top: 0.35rem;
}
@media (max-width: 767.98px) {
  .c-sideheader__lang {
    font-size: 2.5rem;
  }
}
.c-sideheader__lang .c-sideheader__custom-language-switcher {
  color: inherit;
  text-transform: uppercase;
  text-decoration: none;
  font-family: "ABCCamera_Regular", sans-serif;
}
.c-sideheader__lang .c-sideheader__custom-language-switcher + .c-sideheader__custom-language-switcher {
  margin-left: 1rem;
}

@media (max-width: 767.98px) {
  .c-sideheader__search {
    display: none;
  }
}

.c-sideheader__search .searchform input {
  border: 0;
  margin-top: 0.4rem;
  text-align: right;
  font-family: "ABCCamera_Regular", sans-serif;
  font-size: 1.4rem;
}
.c-sideheader__search .searchform input:focus-visible {
  outline: 0;
}
.c-sideheader__search .searchform input::placeholder {
  color: #000;
}
.c-sideheader__search .searchform input[type=submit] {
  display: none;
}

.c-sideheader__button {
  border: 0;
  background: transparent;
  cursor: pointer;
  position: relative;
  top: 2px;
}
@media (max-width: 767.98px) {
  .c-sideheader__button {
    transform: scale(1.5);
  }
}

.c-sideheader__audio {
  opacity: 0.25;
}

/* ==========================================================================
   #SUBHEADER
   ========================================================================== */
.c-subheader {
  width: 100%;
  bottom: 0;
  left: 0;
  background: #fff;
  z-index: 1;
  /* stylelint-disable */
  /* stylelint-enable */
}
@media (min-width: 1390px) {
  .c-subheader.is-hidden {
    z-index: -1;
    position: fixed;
    width: calc(100% - 2rem);
    top: 0;
  }
}
.is_bg_image .c-subheader {
  background: transparent;
}
@media (min-width: 768px) {
  .c-subheader {
    max-width: 136rem;
    background: transparent;
    box-shadow: none;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding-top: 5rem;
  }
}

.c-tease-category__title {
  font-weight: bold;
  text-align: left;
  border-bottom: 2px solid;
  padding-bottom: 0.5rem;
  margin: 4rem auto;
  max-width: 65rem;
}

.c-tease__news-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid;
  margin-bottom: 2rem;
}

.c-tease__news-title {
  font-size: 1.8rem;
}

/* stylelint-disable */
.c-tease__news-header * {
  font-weight: normal;
  text-decoration: none;
  color: #000;
  padding-bottom: 0.5rem;
  font-size: 1.8rem;
}
.c-tease__news-header *:hover {
  color: inherit;
}

.c-tease__news-excerpt * {
  text-decoration: none;
  color: #000;
}
.c-tease__news-excerpt *:hover {
  color: inherit;
}
.c-tease__news-excerpt.is-hidden {
  display: none;
}

.c-tease--search {
  border-bottom: 2px solid;
  margin-bottom: 1.5rem;
}

.c-tease--search a {
  text-decoration: none;
  color: #000;
}

.c-tease--search .read-more {
  float: right;
  font-size: 1.2rem;
  position: relative;
  top: 1.5rem;
}

/* ==========================================================================
   #ALIGN
   ========================================================================== */
.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-clear {
  clear: both;
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

/* ==========================================================================
   #ANIMATIONS
   ========================================================================== */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes collapsePreheader {
  0% {
    height: 100vh;
  }
  100% {
    height: 0;
  }
}
@keyframes collapseExpandToggle {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}
/* ==========================================================================
   #CLEARFIX
   ========================================================================== */
/**
 * Attach our clearfix mixin to a utility class.
 */
.u-clearfix::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

/* ==========================================================================
   #HIDE
   ========================================================================== */
/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/**
 * Hide visually and from screen readers.
 */
.u-hidden {
  display: none !important;
}

.u-hidden\@small {
  display: none;
}
@media (min-width: 768px) {
  .u-hidden\@small {
    display: block;
  }
}

@media only screen and (max-width: 781px) {
  .u-mobile-hide {
    display: none;
  }
}

@media (min-width: 1024px) {
  .u-desktop-hide {
    display: none;
  }
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */
/**
 * Utility classes to add properties to images
 */
.u-enlarged img {
  width: 100%;
}
@media (min-width: 768px) {
  .u-enlarged img {
    width: 120%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}

.u-proportion-outer {
  position: relative;
}

.u-proportion-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
}

.u-hr {
  border-style: solid;
  background: black;
  margin-inline: 1rem;
}
@media (min-width: 768px) {
  .u-hr {
    margin-inline: 0;
  }
}