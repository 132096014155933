/* ==========================================================================
   #BODY
   ========================================================================== */

/* stylelint-disable */
body {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2rem;
  overflow: hidden;

  &.is_bg_image {
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
  }

  &.is-safari.is_bg_image::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}
