/* stylelint-disable */
@media only screen and (max-width: 1099px) {
  #cookie-notice:not(.cookie-notice-hidden) .cookie-notice-container {
    display: flex !important;
    gap: 20px;
  }
}

#cookie-notice .cookie-notice-container {
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
  min-height: 8rem;

  #cn-notice-buttons.cn-buttons-container {
    display: flex !important;
  }

  @media only screen and (max-width: 1099px) {
    #cn-notice-buttons.cn-buttons-container {
      flex-direction: column;
      justify-content: center;
    }
  }

  @media only screen and (min-width: 1100px) {
    #cn-notice-buttons.cn-buttons-container {
      width: 100%;
      top: 50%;
      left: 50%;
      max-width: 137rem;
      position: absolute;
      transform: translate(-50%, -50%);
      padding: 0 1rem;
    }
  }

  @media only screen and (min-width: 1390px) {
    #cn-notice-buttons.cn-buttons-container {
      padding: 0;
    }
  }

  #cn-more-info.cn-button.cn-more-info {
    margin: 0;
    text-transform: lowercase;
  }

  @media only screen and (max-width: 1099px) {
    #cn-more-info.cn-button.cn-more-info {
      position: absolute;
      bottom: 2.5rem;
      left: 3rem;
      font-size: 1.2rem;
    }
  }

  @media only screen and (min-width: 1100px) {
    #cn-more-info.cn-button.cn-more-info {
      order: -1;
      margin-right: auto;
      margin-left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem !important;
    }
  }

  .cn-button {
    text-decoration: none;
    padding: 0 !important;
  }

  #cn-accept-cookie {
    color: transparent;
    font-size: 0;
    line-height: 0;
  }

  @media only screen and (max-width: 1099px) {
    #cn-accept-cookie::after {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='53' height='53' viewBox='0 0 53 53' fill='none'%3E%3Cpath d='M46.2107 26.4998C46.2107 15.2183 37.249 6.07275 26.1942 6.07275C15.1394 6.07275 6.17773 15.2183 6.17773 26.4998C6.17773 37.7814 15.1394 46.9269 26.1942 46.9269C37.249 46.9269 46.2107 37.7814 46.2107 26.4998Z' stroke='%231B1B1B' stroke-width='1.5'/%3E%3Cpath d='M19.7012 26.5002L24.0291 30.9168L32.6848 22.0835' stroke='%231B1B1B' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    }
  }

  @media only screen and (min-width: 1100px) {
    #cn-accept-cookie::after {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M21.25 12C21.25 6.89137 17.1086 2.75 12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12Z' stroke='%231B1B1B' stroke-width='1.5'/%3E%3Cpath d='M9 12L11 14L15 10' stroke='%231B1B1B' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    }
  }

  #cn-refuse-cookie {
    color: transparent;
    font-size: 0;
    line-height: 0;
  }

  @media only screen and (max-width: 1099px) {
    #cn-refuse-cookie::after {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='54' height='54' viewBox='0 0 54 54' fill='none'%3E%3Cpath d='M31.0925 22.5L22.2734 31.5' stroke='%231B1B1B' stroke-width='1.5' stroke-linecap='round'/%3E%3Cpath d='M31.0925 31.5L22.2734 22.5' stroke='%231B1B1B' stroke-width='1.5' stroke-linecap='round'/%3E%3Cpath d='M47.0774 27C47.0774 15.5056 37.9466 6.1875 26.6832 6.1875C15.4198 6.1875 6.28906 15.5056 6.28906 27C6.28906 38.4944 15.4198 47.8125 26.6832 47.8125C37.9466 47.8125 47.0774 38.4944 47.0774 27Z' stroke='%231B1B1B' stroke-width='1.5'/%3E%3C/svg%3E");
    }
  }

  @media only screen and (min-width: 1100px) {
    #cn-refuse-cookie::after {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M14 10L10 14' stroke='%231B1B1B' stroke-width='1.5' stroke-linecap='round'/%3E%3Cpath d='M14 14L10 10' stroke='%231B1B1B' stroke-width='1.5' stroke-linecap='round'/%3E%3Cpath d='M21.25 12C21.25 6.89137 17.1086 2.75 12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12Z' stroke='%231B1B1B' stroke-width='1.5'/%3E%3C/svg%3E");
    }
  }
}

#cn-notice-text.cn-text-container {
  display: block;
  margin: 1rem auto;
}

@media only screen and (max-width: 1099px) {
  #cn-notice-text.cn-text-container {
    text-align: left;
    margin: 0 auto 5rem;

    &::before {
      content: "ciasteczka";
      display: block;
      font-size: 1.2rem;
      margin: 1rem 0 2rem;
    }
  }
}

@media only screen and (min-width: 1100px) {
  #cn-notice-text.cn-text-container {
    max-width: 86rem;
  }
}

#cn-close-notice {
  display: none;
}
