/* ==========================================================================
   #MAIN NAV
   ========================================================================== */

/* stylelint-disable */

.c-main-nav__wrapper {
  position: relative;
  z-index: 100;

  .is_bg_image & * {
    @include bp(large) {
      color: $color-white !important;

      *, & {
        background-color: transparent !important;
      }
    }
  }
}

.c-main-nav {
  display: flex;
  margin: 0;
  transition: all 0.33s;
  z-index: 10;

  @include bp-down(large) {
    flex-direction: column;
    position: absolute;
    top: 3.7rem;
    left: -1rem;
    background: $color-white;
    width: 100vw;
    transform: translateX(-100%);
    height: 100vh;
  }
}

.c-main-nav__toggle {
  background: none;
  border: 0;
  font-size: 2.5rem;
  padding: 0;
  margin-top: .5rem;
  display: block;
  color: $color-black;

  @include bp(large) {
    display: none;
  }

  .is_bg_image & {
    color: $color-white;
  }
}

.is-open .c-main-nav {
  @include bp-down(large) {
    transform: translateX(0%);
    overflow-y: auto;

    &::before {
      content: '';
      width: 100%;
      position: fixed;
      top: 0;
      background: white;
      z-index: -1;
    }

    &::after {
      content: '';
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.05) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      width: 10rem;
      height: 100vh;
      position: absolute;
      top: 0;
      right: -10rem;
    }
  }
}

.c-main-nav__item {
  list-style: none;
  margin: 0 1em 0 0;
  padding: 0;
  position: relative;

  @include bp-down(large) {
    border-bottom: 2px solid $color-black;
    font-size: 3rem;
    text-align: center;
    text-transform: uppercase;
    margin: 0 0.75em 0 0.75em;
    -webkit-tap-highlight-color: transparent !important;
  }

  &--footer {
    margin: 6rem 2rem;
    list-style: none;
  }
}

.c-main-nav__link {
  color: $color-black;
  display: block;
  padding: 0.7em 1em 0.5em;
  text-decoration: none;
  line-height: 1;

  @include bp(large) {
    font-family: 'ABCCamera_Regular', sans-serif;
    padding: 0.7em 1em 0.6em 0;
    line-height: 1.4;
  }
}

.c-main-nav__expand {
  display: none;
}

.c-main-nav__dropdown {
  display: none;

  @include bp(large) {
    flex-direction: column;
    margin: 0;
    position: absolute;
    z-index: 99;
    border-width: 2px 2px 2px;
    border-color: $color-white $color-black $color-black;
    border-style: solid;
    left: -2rem;
    padding: 0 1rem 0.5rem;
    background: $color-white;
    text-align: left;
    min-width: max-content;

    .is_bg_image & {
      border-width: 3px 3px 3px;
      border-color: $color-black $color-white $color-white;
      background: rgba($color-black, .5) !important;
    }

    &.is-visible {
      display: flex;
    }
  }

  .c-main-nav__item.is-expanded & {
    display: block;
  }

  + .c-main-nav__expand {
    @include bp-down(large) {
      display: block;
    }

    position: absolute;
    right: 0;
    top: 3.25rem;
    background: transparent;
    border: 0;
    width: 2.4rem;
    height: 2.4rem;
    transform: translateY(-50%);

    &::before,
    &::after {
      content: '';
      background: $color-black;
      height: $global-border-width;
      width: 100%;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(90deg);
      transform-origin: center;
    }

    .c-main-nav__item.is-expanded & {
      &::after {
        animation: collapseExpandToggle 0.25s;
        animation-fill-mode: forwards;
      }
    }
  }
}

.c-main-nav__subitem {
  list-style: none;
  margin: 0;
  min-width: 20rem;

  @supports (min-width: max-content) {
    min-width: max-content;
  }

  @include bp-down(large) {
    font-size: 1.6rem;
    text-transform: none;
  }
}

.c-main-nav__sublink {
  color: $color-black;
  background: $color-white;
  display: block;
  padding: 0.5em;
  text-decoration: none;

  @include bp-down(large) {
    &:first-child {
      padding-top: 0;
    }

    padding-bottom: 2rem;
  }
}

.current-menu-item .c-main-nav__sublink {
  border-bottom-color: $color-black;
}
