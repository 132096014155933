/* ==========================================================================
   #PRE LOGO VISIBLE ON PREHEADER SCREEN
   ========================================================================== */

.c-pre-logo {
  position: absolute;
  z-index: 1;
  width: 90vw;
  top: 3rem;
  left: 50%;
  transform: translateX(-50%);
}
