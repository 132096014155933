/* ==========================================================================
   #PAWILON CALENDAR
   #DEPENDS ON: THE EVENTS CALENDAR PRO PLUGIN
   ========================================================================== */

/* stylelint-disable */
.b-pawilon-calendar {
  width: 100%;
  position: relative;

  .tribe-events-widget-shortcode__header-title {
    display: none;
  }
  .tribe-events {
    font-size: 3.4rem;
    text-transform: uppercase;
    padding: 0 !important;
    .tribe-events-header {
      border-bottom: 2px solid currentColor;
      margin-bottom: 1.5rem !important;
      padding-inline: 0px;
      margin-inline: auto;
      max-width: 100%;
    }
    .tribe-events-calendar-month__day {
      .tribe-events-calendar-month__day-cell {
        border-radius: 100%;
        justify-content: center;
        top: 50% !important;
        left: 50%;
        width: 90%;
        height: 90%;
        transform: translate(-50%, -50%);
        @include bp(medium) {
          width: 66%;
          height: 66%;
        }
        h3 {
          font-size: 2rem;
          position: relative;
          top: 0.1rem;
          color: $color-medium-grey;
          @include bp(medium) {
            font-size: 3.4rem;
          }
        }
        &:focus {
          background: transparent !important;
          h3 {
            color: $color-black;
          }
        }
        &--selected {
          border-color: $color-black;

          &:focus {
            border: 2px solid $color-black;
            @include bp(medium) {
              border-width: 4px;
            }
            .tribe-events-calendar-month__mobile-events-icon.tribe-events-calendar-month__mobile-events-icon--event,
            .tribe-events-calendar-month__mobile-events-icon.tribe-events-calendar-month__mobile-events-icon--featured {
              border: 0;
            }
          }
        }
      }
      &--current .tribe-events-calendar-month__day-cell {
        background: $color-black;
        border: 2px solid $color-black;
        @include bp(medium) {
          border-width: 4px;
        }
        h3 {
          color: $color-white;
        }
      }
      &--other-month {
        opacity: .5;
      }
      &:hover .tribe-events-calendar-month__day-cell {
        background: $color-black !important;
        h3 {
          color: $color-white;
        }
        .tribe-events-calendar-month__mobile-events-icon {
          border-color: transparent !important;
        }
      }

      .tribe-events-calendar-month__mobile-events-icon.tribe-events-calendar-month__mobile-events-icon--featured {
        .tribe-common-c-svgicon--featured {
          display: none;
        }
      }
    }

    .tribe-events-calendar-month__day--no-events {
      .tribe-events-calendar-month__mobile-events-icon {
        display: none;
      }
    }
    .tribe-events-calendar-month__day--has-events {
      .tribe-events-calendar-month__mobile-events-icon {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: transparent;
        border-radius: 100%;
        border: 2px solid $color-medium-grey;
        @include bp(medium) {
          border-width: 4px;
        }
      }
    }
    .tribe-events-calendar-month__day--has-multiday-events:not(.tribe-events-calendar-month__day--has-events) {
      .tribe-events-calendar-month__mobile-events-icon {
        display: block;
        width: 6px;
        height: 6px;
        position: absolute;
        background-color: $color-black;
        border: 0;
        border-radius: 100%;
        bottom: 0;
        transition: all .1s;
      }
      &.tribe-events-calendar-month__day--current {
        .tribe-events-calendar-month__day-cell {
          .tribe-events-calendar-month__mobile-events-icon {
            bottom: -1.4rem;
          }
        }
      }
      .tribe-events-calendar-month__day-cell {
        &:focus {
          .tribe-events-calendar-month__mobile-events-icon {
            bottom: -1.4rem;
          }
        }
      }
    }
  }

  .tribe-common .tribe-common-l-container {
    padding-inline: 0;
  }

  .tribe-common-c-svgicon--pawilon-arrow {
    width: 2.2rem;
    position: relative;
    top: -0.2rem;
  }

  .tribe-events-calendar-month__header-column h3.tribe-events-calendar-month__header-column-title {
    text-align: center;
    font-size: 2rem;
    font-family: 'ABCCamera_Bold', sans-serif;;
  }

  .tribe-events-c-top-bar__nav-list-date {
    font-size: 2rem;
    @include bp(medium) {
      font-size: 3.5rem;
    }
  }

  .tribe-events-widget-events-month__view-more {
    display: none;
  }

  .tribe-events-calendar-month-mobile-events__mobile-day-today .tribe-events-calendar__pawilon-events-other-day {
    display: none;
  }

  .tribe-events-calendar__pawilon-event {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
    text-transform: initial;

    > * {
      font-size: 1.3rem;
      line-height: 1.5;
      @include bp(medium) {
        font-size: 1.8rem;
      }
    }

    .tribe-events-calendar__pawilon-date {
      width: 20%;
      flex-shrink: 0;

      @include bp(medium) {
        width: 18%;
      }

      > * {
        font-weight: normal;
      }
    }
    .tribe-events-calendar__pawilon-title {
      margin-right: auto;
      max-width: 65%;

      > * {
        font-family: 'ABCCamera_Bold', sans-serif;;
      }
    }
    .tribe-events-calendar__pawilon-ticket {
      width: 18%;
      flex-shrink: 0;
      text-align: right;

      a {
        font-family: 'ABCCamera_Bold', sans-serif;;
      }
    }
  }

  .tribe-events-calendar__pawilon-day-header {
    text-align: center;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    border-bottom: 2px solid;
    line-height: initial;
    &:not(:first-child) {
      display: none;
    }
  }

  .tribe-events-calendar__pawilon-day-empty {
    font-size: 1.8rem;
    text-align: center;
  }

  .tribe-events-calendar-month-mobile-events__mobile-day {
    &--show {
      opacity: 0;
      animation: fadeIn 1s;
      animation-fill-mode: forwards;
      overflow: hidden;
    }
  }

  .tribe-events-calendar__pawilon-no-events .tribe-events-c-messages__message {
    justify-content: center;
    font-size: 1.8rem;
    line-height: 2.7rem;
    svg {
      display: none;
    }
  }
}


