/* ==========================================================================
   #LOGO
   ========================================================================== */

/* stylelint-disable */

.c-logo {
  display: none;

  @include bp(medium) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    .is-hidden {
      animation: fadeOut 0.1s;
      animation-fill-mode: forwards;
    }
  }

  .is_bg_image & path {
    fill: $color-white;
  }
}

.c-logo__part--p {
  width: 16%;

  @include bp(medium) {
    &.is-sticky {
      // WIP
    }
  }
}

.c-logo__part--awilon {
  width: 83.2%;
  margin-bottom: 9%;
  transform: rotateY(0deg);
  transform-origin: left;
}
