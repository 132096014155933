/* ==========================================================================
   #PAWILON TV
   ========================================================================== */

@mixin tv-item-width {
  width: calc(100vw - 2rem);
  margin-left: 1rem;

  @include bp(medium) {
    width: 100%;
    margin: 0;
  }
}

.c-pawilon-tv {
  background-color: $color-yellow;
  min-height: 4rem;
  position: fixed;
  display: flex;
  align-items: center;
  z-index: 11;
  width: 100%;
  /* stylelint-disable */
  //display: none !important; //force hide
  /* stylelint-enable */
}

.c-pawilon-tv__init-bar {
  display: flex;
  justify-content: center;
  width: 100%;
  cursor: pointer;

  &::after {
    display: none !important;
  }

  @include bp(medium) {
    justify-content: space-between;
  }
}

.c-pawilon-tv__init-bar-label {
  text-transform: uppercase;

  @include bp-down(medium) {
    width: 25%;
    text-align: center;
  }
}

.c-pawilon-tv__init-bar-teaser {
  margin-inline: auto;
  font-weight: bold;
  flex-grow: 1;
  text-align: center;
  overflow: hidden;
  position: relative;

  &::before {
    content: '';
    display: block;
    background: rgb(253, 237, 95);
    background: linear-gradient(
      270deg,
      rgba($color-yellow, 0) 0%,
      rgba($color-yellow, 1) 100%
    );
    width: 5rem;
    height: 2rem;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }

  &::after {
    content: '';
    display: block;
    background: rgb(253, 237, 95);
    background: linear-gradient(
      90deg,
      rgba($color-yellow, 0) 0%,
      rgba($color-yellow, 1) 100%
    );
    width: 5rem;
    height: 2rem;
    position: absolute;
    right: 0;
    top: 0;
  }

  @include bp-down(medium) {
    display: none;
    width: 55%;
    margin-right: auto;

    span {
      display: block;
      width: max-content;
    }
  }
}

.c-pawilon-tv__init-bar-cta {
  @include bp-down(medium) {
    display: none;
    width: 20%;
  }
}

.c-pawilon-tv__content {
  display: none;

  &.is-content-expanded {
    display: block;
    width: 100%;
  }
}

.c-pawilon-tv__close {
  background: none;
  border: none;
  display: block;
  width: 5rem;
  cursor: pointer;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
}

.c-pawilon-tv__video {
  display: block;

  /*
  overflow: hidden;

  @include bp-down(medium) {
    min-height: 50vw;
  }

  @include bp(medium) {
    max-height: calc(100vh - 8rem);
  }

  */

  @include tv-item-width;

  position: relative;
}

.c-pawilon-tv__play {
  width: 7.4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.c-pawilon-tv__panel {
  display: flex;
  justify-content: space-between;

  @include tv-item-width;

  padding: 1rem 0;
  border-top: $global-border-width solid $color-black;

  @include bp(medium) {
    padding: 1rem 1.5rem;
  }
}

.c-pawilon-tv__nav {
  width: 9.8rem;
  flex-shrink: 0;
}

.c-pawilon-tv__nav-icon {
  width: 4rem;
  display: block;

  &.is-hidden {
    display: none;
  }
}

.c-pawilon-tv__desc-expanded {
  padding: 3rem 1rem;
  transition: $global-transition;
  display: none;

  &.is-visible {
    display: block;
  }

  @include bp(medium) {
    padding: 3rem 14.3rem;
  }
}

.c-pawilon-tv__top-bar {
  height: 4rem;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;

  @include bp(medium) {
    display: none;
  }
}

.c-pawilon-tv__label {
  display: none;
  font-size: 1.2rem;

  @include bp(medium) {
    display: block;
  }
}

.c-pawilon-tv__next {
  display: none;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;

  @include bp(medium) {
    display: flex;
  }
}

.c-pawilon-tv__prev {
  display: none;

  @include bp(medium) {
    display: flex;
    flex-direction: column;
  }
}

.c-pawilon-tv__now {
  width: 50%;
  margin-right: auto;
  cursor: pointer;

  @include bp(medium) {
    margin-left: 3rem;
  }
}

.c-pawilon-tv__desc {
  width: 20%;
}

.c-pawilon-tv__desc-button {
  background: transparent;
  border: 0;
  cursor: pointer;
}

.c-pawilon-tv__title {
  font-size: 1.8rem;
  font-weight: bold;
  text-transform: none;
  margin-bottom: 0;
}

.is-content-collapsed {
  display: none;
}

.is-content-expanded {
  min-height: 100vh !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

.c-pawilon-tv__more-wrapper {
  position: relative;
}

.c-pawilon-tv__more-posts {
  display: grid;
  gap: 3rem;
  margin-inline: 1.5rem;
  margin-top: 3rem;
  margin-bottom: 5rem;
  min-height: 20rem;

  @include bp(medium) {
    margin-inline: 8rem;
    grid-template-columns: repeat(5, 1fr);
  }

  @media screen and (orientation: portrait) {
    min-height: 50vh;
  }
}

.c-pawilon-tv__more-title {
  text-align: left;
  font-size: 1.8rem;
  font-weight: normal;

  @include bp(medium) {
    font-size: 1.4rem;
  }
}

.c-pawilon-tv__more-post {
  text-decoration: none;
}

.c-pawilon-tv__more-post svg {
  width: 5rem;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.c-pawilon-tv__more-button {
  display: none;
  border: 0;
  background: transparent;
  width: 3rem;
  position: absolute;
  /* stylelint-disable */
  top: calc( ( ((100vw - 280px) / 5) * 0.66) / 2 );
  /* stylelint-enable */
  cursor: pointer;
  transform: translateY(-50%);

  @include bp(medium) {
    display: block;
  }
}

.c-pawilon-tv__more-button.prev {
  left: 1rem;
  transform: translateY(-50%) rotate(180deg);
}

.c-pawilon-tv__more-button.next {
  right: 1rem;
}

.c-pawilon-tv__all {
  background: transparent;
  border: 0;
  margin: 0 auto;
  display: block;
  font-size: 1.4rem;
  padding-bottom: 2rem;
  font-family: inherit;
  cursor: pointer;
}

.c-pawilon-tv__more-loader {
  position: absolute;
  top: 4rem;
  left: 50%;
  transform: translateX(-50%);

  &.is-hidden {
    display: none;
  }
}

.c-pawilon-tv__close-bar {
  cursor: pointer;
  background: $color-white;
  padding: 1.2rem 8rem;
  font-size: 1.4rem;
  text-align: center;

  @include bp(medium) {
    font-size: 1.8;
    text-align: left;
  }
}
