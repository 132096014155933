/* ==========================================================================
   #FOOTER
   ========================================================================== */

/* stylelint-disable */

.c-footer {
  padding-top: 1rem;
  position: relative;
  margin-inline: 0;
  margin-top: 12rem;
  margin-bottom: 0;
  background: $color-very-light-grey;

  .is_bg_image & {
    @include bp-down(large) {
      background: transparent;
      color: $color-white;
    }
  }

  @include bp(medium) {
    margin-top: 10rem;
    margin-inline: 1rem;
  }

  @include bp(xlarge) {
    margin-top: 10rem;
    margin-inline: auto;
    max-width: 137rem;
  }

  &::before {
    content: '';
    background: #f5f5f5;
    position: absolute;
    top: -3rem;
    height: 100vh;
    width: 100vw;
    z-index: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .is_bg_image &::before {
    @include bp-down(large) {
      background: transparent;
    }
  }

  &::after {
    content: "";
    height: $global-border-width;
    width: 100%;
    background-color: $color-black;
    display: block;
    position: absolute;
    z-index: 1;
    top: -2px;

    @include bp-down(medium) {
      width: calc(100% - 2rem);
      left: 1rem;
    }
  }

  .is_bg_image &::after {
    @include bp-down(large) {
      background-color: $color-white;
    }
  }

  a {
    text-decoration: none;

    .is_bg_image & {
      @include bp-down(large) {
        color: $color-white;
      }
    }

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    margin-bottom: 0;
  }

  .wp-block-columns {
    row-gap: 0;
    column-gap: 2.75rem;
    margin-inline: 1rem;
    position: relative;
  }

  .wp-block-column {
    margin-left: 0 !important;

    @include bp-down(medium) {
      margin-bottom: 1rem;
    }
  }

}

.c-footer__mobile-image {
  margin-left: -1rem;
  margin-right: -1rem;
  margin-bottom: 0;
  position: relative;

  img {
    width: 100%;
    height: auto;
  }

  @include bp(medium) {
    display: none;
  }
}

.c-footer__mobile-logo {
  position: relative;
  width: calc(100% - 2rem);
  left: 1rem;
  margin-top: 2rem;

  svg path {
    fill: $color-black;
  }

  @include bp(medium) {
    display: none;
  }

  .is_bg_image & svg path {
    @include bp-down(large) {
      fill: $color-white
    }
  }

}
