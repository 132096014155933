.b-side-label {
  font-size: 1.4rem;
  width: calc(100vw - 2 * 1.3rem);
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  max-width: 137rem;

  @include bp-down(large) {
    display: none;
  }

  .is-wrapped & {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 0;
  }
}

.b-side-label__rotated {
  transform: rotate(90deg);
  display: inline-block;
  transform-origin: bottom left;
  margin-bottom: 0;

  span {
    display: block;
    transform: rotate(180deg);
  }

  .is-wrapped & {
    transform: rotate(90deg) translateX(-50%);
  }
}
