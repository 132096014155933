/* ==========================================================================
   #ACCORDIONS
   ========================================================================== */

.b-accordions {
  @include bp-down(large) {
    column-width: auto !important;
  }
}

.b-accordions--columns {
  display: grid;
  grid-column-gap: $global-gap;

  @include bp(large) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.b-accordions__item {
  border-bottom: $global-border-width solid $color-black;
  margin-bottom: 1rem;

  h4 {
    margin-bottom: 0;
  }
}

.b-accordions__item-title {
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  padding-top: 2rem;
  font-size: 3rem;
  font-weight: 500;
  line-height: 145%;
  border-top: 3px solid $color-light-grey;

  @include bp(medium) {
    font-size: 4rem;
    line-height: 165%;
  }
}

.b-accordions__item:first-child .b-accordions__item-title {
  margin-top: 0;
  padding-top: 0;
  border-top: 0;
  border-top: none;
}

.b-accordions__item-toggle {
  margin-bottom: 0.5rem;
  padding: 0;
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  font: inherit;
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 136%;
  text-align: left;
  appearance: none;
  cursor: pointer;
}

.b-accordions__item-content {
  height: 0;
  overflow: hidden;
  transition: $global-transition;

  .no-js & {
    height: auto;
  }
}

.b-accordions__item-content-inner {
  padding-bottom: 3rem;
}
