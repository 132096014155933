/* ==========================================================================
   #PAWILON LIST
   #DEPENDS ON: THE EVENTS CALENDAR PRO PLUGIN
   ========================================================================== */

/* stylelint-disable */

.b-pawilon-list {
  font-family: $font-sans-serif;

  .tribe-events-l-container {
    padding: 0 !important;
    min-height: 0 !important;
  }

  nav.tribe-events-calendar-list-nav {
    width: 95vw;
    max-width: 139rem;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    padding-top: 0;
    top: 0;

    @include bp(medium) {
      position: relative;
    }
  }

  @include pawilon-list-styling;

  .tribe-events-calendar__nav-common, .tribe-events-calendar__nav-common button {
    width: 5rem;
    background: none;
    border: 0;

    @include bp(medium) {
      width: 26rem;
    }
  }

  .tribe-events-calendar__nav-prev {
    transform: rotate(90deg);

    &--disabled {
      opacity: .25;
    }
  }

  .tribe-events-calendar__nav-next {
    transform: rotate(270deg);

    &--disabled {
      opacity: .25;
    }
  }

  .tribe-events-calendar__pawilon-event-image {
    margin-bottom: 2rem;
    @include bp(medium) {
      padding-top: .5rem;
      border-top: 2px solid;
    }
  }

  &.is_featured {
    .tribe-events-header--has-no-events {
      display: none;
    }
    .tribe-events-calendar-list__month-separator,
    .tribe-events-calendar-list__tag-name {
      display: none;
    }
    @include bp-down(medium) {
      .tribe-events-calendar__pawilon-list-tags {
        width: 50%;
      }
      .tribe-events-calendar__pawilon-list-date {
        width: 100%;
        font-size: 10rem;
      }
      .tribe-events-calendar__pawilon-list-title {
        width: 100%;
        margin-top: 8rem;

        .is-safari & {
          letter-spacing: -2px;
        }

        a {
          font-size: 3.3rem;
        }
      }
    }
  }

  .home &.is_featured {
    margin-top: 1.6rem;
  }
}
