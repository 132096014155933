/* ==========================================================================
   #WP BLOCKS
   ==========================================================================
   Styling for WordPress blocks
   */

.wp-block-post-template {
  margin-left: 0;
}

.c-main .o-main-content .wp-block-file {
  font-size: inherit;
  margin: 0;
  line-height: inherit;
}
