/* ==========================================================================
   #PRE ARROW VISIBLE ON PREHEADER SCREEN
   ========================================================================== */

/* stylelint-disable */

.c-pre-arrow {
  position: absolute;
  bottom: 8rem;
  left: 3%;
  width: 17vw;
  cursor: pointer;
  z-index: 1;

  @include bp(large) {
    bottom: 2rem;
  }
}
