/* stylelint-disable */

article {
  max-width: 65rem;
  margin: 0 auto;

  @include bp(medium) {
    position: relative;
    z-index: 10;
  }

  .is_bg_image & {
    * {
      color: $color-white;
      border-color: $color-white;
      background-color: transparent;
    }
  }
}
