/* ==========================================================================
   #HEADER
   ========================================================================== */

/* stylelint-disable */

.c-header {
  border-bottom: $global-border-width solid $color-black;
  position: fixed;
  width: calc(100% - 2rem);
  z-index: 100;
  transform: translateY(4rem);

  @include bp(medium) {
    background: $color-super-light-grey;
  }

  @include bp(xlarge) {
    width: 100%;
    left: 50%;
    transform: translate(-50%, 4rem);
  }

  @include bp-down(large) {
    height: 4rem;
  }

  .is_bg_image & {
    border-color: $color-white;
    background-color: transparent;
  }
}

.c-header__inner {
  display: flex;
  justify-content: space-between;
  background-color: $color-super-light-grey;
  position: relative;
  max-height: calc(4rem - 1px);

  .is_bg_image & {
    background-color: transparent !important;
  }

  &::before {
    content: '';
    background: $color-super-light-grey;
    width: 100vw;
    height: calc(4rem - 1px);
    position: absolute;
    z-index: -1;
    top: -1px;
    transform: translateX(-50%);
    left: 50%;
  }
  .is_bg_image &::before {
    background-color: rgba($color-black, .5);
  }
}
