.b-team {
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;

  @include bp(medium) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    margin-bottom: 15rem;
  }
}

.b-team__text {
  font-size: 1.2rem;
  line-height: 1.6rem;
  padding-inline: 2rem;

  @include bp(medium) {
    padding-inline: 0;
  }
}

.b-team .b-team__name {
  font-size: 4rem;
  line-height: 3.4rem;
  text-transform: none;
  margin-top: 0;
  border-bottom: 0;
  padding-bottom: 0;
  text-align: inherit;
}

.b-team__photo-wrapper {
  padding-top: 125%;
  margin-bottom: 3rem;

  figcaption {
    position: absolute;
    font-size: 1rem;
    left: 0;

    .is-reversed & {
      left: auto;
      right: 0;
    }
  }
}

@include bp(medium) {
  .b-team.is-reversed .b-team__photo-wrapper {
    grid-column: 2;
  }

  .b-team.is-reversed .b-team__text {
    grid-row: 1;
    grid-column: 1;
  }
}
