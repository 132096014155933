.c-tease-category__title {
  font-weight: bold;
  text-align: left;
  border-bottom: 2px solid;
  padding-bottom: 0.5rem;
  margin: 4rem auto;
  max-width: 65rem;
}

.c-tease__news-header {
  display: flex;
  justify-content: space-between;
  border-bottom: $global-border-width solid;
  margin-bottom: 2rem;
}

.c-tease__news-title {
  font-size: 1.8rem;
}

/* stylelint-disable */
.c-tease__news-header * {
  font-weight: normal;
  text-decoration: none;
  color: $color-black;
  padding-bottom: 0.5rem;
  font-size: 1.8rem;

  &:hover {
    color: inherit;
  }
}

.c-tease__news-excerpt {
  * {
    text-decoration: none;
    color: $color-black;

    &:hover {
      color: inherit;
    }
  }

  &.is-hidden {
    display: none;
  }
}

.c-tease--search {
  border-bottom: 2px solid;
  margin-bottom: 1.5rem;
}

.c-tease--search a {
  text-decoration: none;
  color: $color-black;
}

.c-tease--search .read-more {
  float: right;
  font-size: 1.2rem;
  position: relative;
  top: 1.5rem;
}
