// Buy ticket

.b-buy-ticket__link {
  display: flex;
  width: 11.3rem;
  height: 11.3rem;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  border: 2px solid;
  color: $color-black;
  background: $color-white;
  text-decoration: none;
  text-transform: uppercase;
  margin: 5rem auto 0;
}
