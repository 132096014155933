/* ==========================================================================
   #NEWSLETTER FORM
   ========================================================================== */

.b-newsletter-form input {
  background: transparent;
  border: 0;
  padding: 0;
  font-size: 1.4rem;
  font-family: $font-sans-serif;
  color: $color-black;
  font-weight: normal;

  /* stylelint-disable */
  .is_bg_image & {
    @include bp-down(large) {
      color: $color-white;
    }
  }
  /* stylelint-enable */

  &[type='email'] {
    width: 100%;
  }

  &[type='submit'] {
    cursor: pointer;
  }
}
