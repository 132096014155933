.b-block-wrapper {
  position: relative;

  &.is-stretched {
    position: relative;
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);
  }
}
