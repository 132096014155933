/* ==========================================================================
   #MAIN-CONTENT
   ========================================================================== */

/**
 * Page-level object for main page content
 */

.o-main-content {
  @include bp(medium) {
    padding-top: 30vw;

    .home & {
      padding-top: 23vw;
    }
  }

  @include bp(large) {
    padding-top: 30vw;

    .home & {
      padding-top: 22vw;
    }
  }

  @include bp(xlarge) {
    padding-top: 40rem;

    .home & {
      padding-top: 30rem;
    }
  }
}
