/* ==========================================================================
   #ACCORDIONS
   ========================================================================== */

.b-idioms {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 6rem;
  border-bottom: 2px solid;
}

.b-idioms__image-wrapper {
  width: 100%;
}

.b-idioms__image {
  width: 100%;
  margin-bottom: 2rem;
}

.b-idioms__image img {
  width: 100%;
  margin: 0 auto;
  display: block;

  @include bp(large) {
    width: 66%;
  }
}
