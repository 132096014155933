/* ==========================================================================
   #PREHEADER
   ========================================================================== */

/* stylelint-disable */

.c-preheader {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  background-color: $color-white;
  background-size: cover;
  background-position: center;
  z-index: 2;

  @include bp(medium) {
    background-size: 0%;
  }

  &.is-collapsed {
    height: 100vh;
    animation: collapsePreheader .5s;
    animation-fill-mode: forwards;
  }

  &.is-hidden {
    display: none;
  }

  img {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include bp-down(medium) {
      display: none;
    }
  }
}

.c-preheader__label {
  position: absolute;
  bottom: 8rem;
  right: 4%;
  z-index: 1;

  @include bp(large) {
    bottom: 2rem;
  }
}
