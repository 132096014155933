/* ==========================================================================
   #IMAGES
   ========================================================================== */

/**
 * Utility classes to add properties to images
 */

.u-enlarged img {
  width: 100%;

  @include bp(medium) {
    width: 120%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}

.u-proportion-outer {
  position: relative;
}

.u-proportion-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
}
