.post-nav,
.post-nav button {
  width: 5rem;
  background: none;
  border: 0;

  @include bp(medium) {
    width: 26rem;
  }
}

.post-nav__prev {
  transform: rotate(90deg);

  &--disabled {
    opacity: 0.25;
  }
}

.post-nav__next {
  transform: rotate(270deg);

  &--disabled {
    opacity: 0.25;
  }
}

.post-nav__wrapper {
  list-style: none;
  margin: 0;
  display: flex;
  justify-content: space-between;
  width: 95vw;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, calc(100% + 2.5rem));

  @include bp(medium) {
    transform: translate(-50%, 25%);
  }

  @include bp(large) {
    position: relative;
    bottom: auto;
  }
}
