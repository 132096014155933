/* ==========================================================================
   #SUBHEADER
   ========================================================================== */

.c-subheader {
  width: 100%;
  bottom: 0;
  left: 0;
  background: $color-white;
  z-index: 1;

  &.is-hidden {
    @include bp(xlarge) {
      z-index: -1;
      position: fixed;
      width: calc(100% - 2rem);
      top: 0; // substitute this with below value when Pawilon TV not active
    }
  }

  /* stylelint-disable */
  .is_bg_image & {
    background: transparent;
  }
  /* stylelint-enable */

  @include bp(medium) {
    max-width: 136rem;
    background: transparent;
    box-shadow: none;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding-top: 5rem; // substitute this with above value when Pawilon TV not active
  }
}
