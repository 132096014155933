.pawilon-event__meta {
  margin-bottom: 0.5rem;

  @include bp-down(medium) {
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @include bp(medium) {
    margin-left: auto;
  }
}

.pawilon-event__info {
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid;
  margin-bottom: 4rem;

  @include bp(medium) {
    flex-direction: row;
    align-items: flex-end;
  }

  .pawilon-event__date {
    font-size: 4rem;
    line-height: 4rem;
    flex: 0 0 25%;
    margin-bottom: 0.5rem;

    @include bp-down(medium) {
      font-size: 10rem;
      line-height: 1;
    }
  }

  .pawilon-event__title {
    font-size: 1.8rem;
    line-height: 2.2rem;
    margin-bottom: 0.75rem;
    text-transform: none;
    font-weight: bold;
    text-align: left;

    @include bp-down(medium) {
      margin-top: 8rem;
      font-size: 3.3rem;
      line-height: 1;
    }
  }

  .pawilon-event__tag {
    text-decoration: none;
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: $color-black;
    display: block;
  }
}

.pawilon-event {
  @include bp(medium) {
    padding-bottom: 26rem;
  }

  @include bp(large) {
    padding-bottom: 0;
  }

  .tribe-events-event-image img {
    width: 100%;
    height: auto;
  }
}

.pawilon-event__content {
  margin-bottom: 5rem;

  @include bp(large) {
    margin-bottom: 0;
  }
}

.pawilon-event__ticket {
  display: flex;
  width: 11.3rem;
  height: 11.3rem;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  border: 2px solid;
  color: $color-black;
  background: $color-white;
  text-decoration: none;
  text-transform: uppercase;
  margin: 0 auto;

  @include bp(large) {
    position: absolute;
    top: 0;
    right: 50%;
    transform: translateX(48rem);
    z-index: 1;
    margin: 0 auto;
  }
}

.pawilon-event__mobile-featured {
  display: block;

  @include bp(medium) {
    display: none;
  }
}

.pawilon-event__desktop-featured {
  display: none;

  @include bp(medium) {
    display: block;
  }
}
