@mixin bold {
  font-family: 'ABCCamera_Bold', sans-serif;
  font-weight: bold;
}

@mixin italic {
  font-style: italic;
}

/* stylelint-disable */
@mixin pawilon-list-styling {
  .tribe-events-calendar-list__month-separator,
  .tribe-events-calendar-list__tag-name {
    font-size: 2.5rem;
    font-weight: normal;
    text-transform: uppercase;
    text-align: center;
  }

  .tribe-events-calendar-list__tag-name {
    padding-top: 6rem;
    padding-bottom: 2rem;
    margin-bottom: 6rem;
    @include bp(medium) {
      padding-top: 0;
      border-bottom: $global-border-width solid $color-black;
    }
  }

  .tribe-events-calendar__pawilon-event-list {
    padding-bottom: 0.8rem;
    margin-bottom: 2rem;
    border-bottom: $global-border-width solid $color-black;

    .is_bg_image & {
      border-bottom-color: $color-white;
    }

    @include bp-down(medium) {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      flex-wrap: wrap;
    }

    @include bp(medium) {
      display: grid;
      grid-template-columns: 25% 60% 15%;
    }
  }

  .tribe-events-calendar__pawilon-events-list + h2 {
    margin-top: 8rem;
  }

  .tribe-events-calendar__pawilon-list-date {
    font-size: 4rem;
    line-height: 1;

    @include bp-down(medium) {
      width: 35%;
      transform: translateY(3px);
    }

    @include bp(medium) {
      // Styling only, when hour on list is hidden
      grid-row: span 2;
      display: flex;
      align-items: flex-end;
      position: relative;
      top: 0.25rem;
    }
  }

  .tribe-events-calendar__pawilon-list-title {
    margin: 0;

    @include bp-down(medium) {
      width: 60%;
    }

    @include bp(medium) {
      grid-column: 2/3;
      grid-row: span 2;
      align-self: flex-end;
    }
  }

  .tribe-events-calendar__pawilon-list-title-link {
    font-size: 2rem;
    line-height: 1;
    color: $color-black;
    text-decoration: none;
    font-weight: bold;

    .is_bg_image & {
      color: $color-white;
    }
  }

  .tribe-events-calendar__pawilon-list-tags {
    text-align: left;
    margin-bottom: 0;
    list-style: none;

    @include bp-down(medium) {
      margin-top: 3rem;
      width: 50%;
      margin-left: auto;
      order: 10;
    }

    @include bp(medium) {
      font-size: 1.2rem;
      line-height: 1.8rem;
      grid-column: 3/-1;
      align-self: flex-end;
      margin-left: 1rem;
      grid-row-start: 1;
      grid-row-end: span 2;
    }

    a {
      text-decoration: none;
      color: $color-black;

      .is_bg_image & {
        color: $color-white;
      }
    }
  }
  /* stylelint-enable */

  .tribe-events-calendar__pawilon-list-tag {
    margin-bottom: 0;
  }

  .tribe-events-calendar__pawilon-list-time-start {
    display: none;

    @include bp-down(medium) {
      width: 40%;
    }

    @include bp(medium) {
      grid-column: 1/2;
    }
  }
}
