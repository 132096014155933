// ==========================================================================
// #GLOBAL
// ==========================================================================
// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

$global-radius: 3px !default;
$global-transition: all 300ms ease-in-out !default;
$global-wrapper-width: 137rem;
$global-border-width: 2px;
$global-gap: 2rem;
$global-transition: all 0.3s;

/* Fonts
   ========================================================================== */

$font-sans-serif: 'ABCCamera_Regular', sans-serif !default;

/* Breakpoints
   ========================================================================== */

$breakpoints: (
  small: 480px,
  medium: 768px,
  large: 1024px,
  mlarge: 1200px,
  xlarge: 1390px,
  full: 1600px,
);

/* Colors
   ========================================================================== */

// Colors shouldn't be used directly but reassigned
$color-white: #fff;
$color-black: #000;
$color-super-light-grey: #fcfcfc;
$color-very-light-grey: #f5f5f5;
$color-light-grey: #999;
$color-medium-grey: #666;
$color-dark-grey: #333;
$color-yellow: #fded5f;

// Text
$color-bg: $color-white;
$color-text: $color-black;

// Links
$color-link: $color-medium-grey;
$color-hover: $color-light-grey;

// Borders
$color-border: $color-very-light-grey;

// Fills
$color-fill: $color-very-light-grey;
