/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

h1 {
  font-size: 2.5rem;
  line-height: 1.2;

  @media not all and (min-resolution: 0.001dpcm) {
    letter-spacing: -2px;
  }
}

h2 {
  font-size: 2.5rem;
}

h3 {
  font-size: 1.8rem;
}

h4 {
  font-size: 1.8rem;
}

h5 {
  font-size: 1.8rem;
}

h6 {
  font-size: 1.8rem;
}

/* stylelint-disable */

body:not(.archive) h2 {
  margin: 4rem 0;
  font-weight: normal;
  text-transform: uppercase;
  text-align: center;

  @media not all and (min-resolution:.001dpcm) {
    letter-spacing: -2px;
  }
}

body.is_bg_image:not(.archive) h2 {
  border-color: $color-white;
}
