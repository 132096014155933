/* ==========================================================================
   #SIDEHEADER @HEADER
   ========================================================================== */

/* stylelint-disable */

.c-sideheader {
  padding: 0.5rem 0;
  display: flex;
  gap: $global-gap;

  .is_bg_image & div {
    path {
      fill: $color-white;
    }

    * {
      color: $color-white;
    }

    input {
      background: transparent;
      border: 1px solid $color-white;

      &::placeholder {
        color: $color-white;
      }
    }
  }
}

.c-sideheader__lang {
  margin-top: 0.35rem;

  @include bp-down(medium) {
    font-size: 2.5rem;
  }

  .c-sideheader__custom-language-switcher {
    color: inherit;
    text-transform: uppercase;
    text-decoration: none;
    font-family: 'ABCCamera_Regular', sans-serif;
  }

  .c-sideheader__custom-language-switcher
    + .c-sideheader__custom-language-switcher {
    margin-left: 1rem;
  }
}

.c-sideheader__search {
  @include bp-down(medium) {
    display: none;
  }
}

.c-sideheader__search .searchform input {
  border: 0;
  margin-top: 0.4rem;
  text-align: right;
  font-family: 'ABCCamera_Regular', sans-serif;
  font-size: 1.4rem;

  &:focus-visible {
    outline: 0;
  }

  &::placeholder {
    color: $color-black;
  }

  &[type='submit'] {
    display: none;
  }
}

.c-sideheader__button {
  border: 0;
  background: transparent;
  cursor: pointer;
  position: relative;
  top: 2px;

  @include bp-down(medium) {
    transform: scale(1.5);
  }
}

.c-sideheader__audio {
  opacity: .25;
}
