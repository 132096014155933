/* ==========================================================================
   #MAIN CONTENT
   ========================================================================== */

.c-main {
  position: relative;
  min-height: 50vh;
  font-size: 1.8rem;
  font-weight: normal;
  line-height: 2.2rem;
  margin-top: 8rem;

  @include bp(medium) {
    margin-top: 4rem;
  }
}
