.c-embed {
  &--video {
    position: relative;
    width: 100%;
    padding-top: 56.6%;

    .is-visible & {
      display: block;
    }

    iframe {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
