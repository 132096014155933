.c-scroll-top {
  display: none;

  @include bp(mlarge) {
    display: block;
    position: absolute;
    right: 0;
    transform: rotate(180deg) translateY(100%);
    width: 26rem;

    .events-single & {
      transform: rotate(180deg) translateY(75%);
    }
  }

  /* stylelint-disable */
  .is_bg_image & {
    path {
      fill: $color-white;
    }
  }
  /* stylelint-enable */
}

.c-scroll-top__wrapper {
  width: 98vw;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  z-index: 1;
}
