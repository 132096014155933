/* ==========================================================================
   #COLORS
   ========================================================================== */

$colors: (
  'white': $color-white,
  'black': $color-black,
  'very-light-grey': $color-very-light-grey,
  'light-grey': $color-light-grey,
  'medium-grey': $color-medium-grey,
  'dark-grey': $color-dark-grey,
);

@each $name, $value in $colors {
  .has-#{$name}-color {
    color: $value;
  }
  .has-#{$name}-background-color {
    background-color: $value;
  }
}
