/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
  @include clearfix();

  margin-right: auto;
  margin-left: auto;
  max-width: $global-wrapper-width;

  @include bp-down(xlarge) {
    margin-right: 1rem;
    margin-left: 1rem;
  }
}
