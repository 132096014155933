.c-list-nav {
  width: 95vw;
  max-width: 139rem;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  padding-top: 0;

  @include bp(medium) {
    position: relative;
  }
}

.c-list-nav__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  list-style: none;
  margin: 0;
}
