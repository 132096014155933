.navigation.pagination {
  margin-top: 6rem;
  text-align: center;

  a,
  span {
    color: $color-black;
    text-decoration: none;
    padding: 0 1rem;
  }

  span {
    opacity: 0.25;
  }
}
