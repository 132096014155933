/* ==========================================================================
   #LINKS
   ========================================================================== */

a {
  color: $color-black;
  text-decoration: underline;

  &:hover {
    color: $color-black;
  }
}
